const taskTemplates = require('../../task-templates');

const tasksData = [
	/* case 1 */
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'sales-paper-doll-marketing',
		layout: 'sales-working-in-marketing',
		avatarId: 'avatar-2',
		text: `Hvad er de vigtigste ting at have med på arbejde i marketing?

		<br/> Vælg, hvad I tror er det rigtige og tryk så OK. `,

		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'name-tag2', title: 'Navneskilt'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'tie', title: 'Slips'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'camera', title: 'Kamera', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'phone', title: 'Smartphone', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'computer', title: 'Computer', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'tv', title: 'Fjernsyn'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'printer', title: 'Printer'
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'marketing-multiple-choice-window-decoration',
		text: `I skal dekorere butikkens vinduer. Men, hvad tror I kan få kunderne til at stoppe op?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Et vindue med så mange varer som muligt', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Et vindue med de nyeste varer i',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Et vindue uden varer i',
			}),
		],
	}),
	/* case 2 */
	Object.assign({}, taskTemplates.pairsTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'marketing-pairs-population',
		text: `Målgrupper handler også om at vide, hvor mange der findes i en given gruppe. 
			<br />Dan par ved at Google og matche tal med den rigtige gruppe.`,
		columnAItems: [
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '1p1mio',
				text: `1,1 millioner`,
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '28percent',
				text: '28%'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'n302000',
				text: `302.000`
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: '2p1mio',
				text: '2,1 mio'
			}),
		],
		columnBItems: [
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'state-pensioners',
				text: 'Folkepensionister',
				connectedToItemId: '1p1mio'

			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'vocational-education',
				text: '15-69-årige har gennemført erhversfaglig udd.',
				connectedToItemId: '28percent'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'receives-su',
				text: 'Modtager SU',
				connectedToItemId: 'n302000'
			}),
			Object.assign({}, taskTemplates.pairsTemplate.itemTemplate, {
				id: 'married',
				text: 'Har valgt at indgå ægteskab',
				connectedToItemId: '2p1mio'
			})
		]
	}),

	/* case 3 */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		taskId: 'marketing-order-adds',
		title: 'Opgave 1',
		text: `Tilbage i 1980 udgjorde avis- og bladannoncer over 50% af reklamebureauernes business - i dag er det kun 8%. Men hvad udgør så de vigtigste områder for reklamebureauerne i dag? Sæt nedenstående i rækkefølge fra størst til mindst. `,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'internet', 
				text: `Internet`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'ads', 
				text: `Udendørs reklamer`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'tv', 
				text: `TV`,
				correctOrderNumbers: [3]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 2A',
		taskId: 'marketing-multiple-choice-influencer',
		text: `I vil betale en influencer for at lave positiv omtale af jeres virksomhed. Prisen afhænger af, om det er en mega-, makro- eller mikro influencer. 
			<br />En mega influencer har mere end 1 mio. følgere
			<br />En makro influencer har mellem 100.000 og 1 mio. følgere
			<br />En mikro influencer har mellem 10.000 og 100.000 følgere
			<br /><br />Hvem af følgende er en mega-influencer?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Anders Hemmingsen', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Kristine Sloth',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Rasmus Brohave',
			}),
		],
	}),
	/* case 4 */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		taskId: 'marketing-order-social-media',
		title: 'Opgave 1',
		text: 'Hvilke sociale medier er bedst til at nå de unge? Sæt dem i rækkefølge fra bedst til værst.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'tiktok', 
				text: `TikTok`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'instagram', 
				text: `Instagram`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'facebook', 
				text: `Facebook`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'twitter', 
				text: `X (tidligere Twitter)`,
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'marketing-multiple-choice-aida-model',
		text: `AIDA-modellen bliver brugt af mange virksomheder.
		<br >Hvad bruges den til?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Til at lave den bedst mulige reklame', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Til økonomistyring',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Til at finde nye og dygtige ansatte',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Til at finde svagheder I virksomheden',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'marketing-multiple-choice-aida-stands-for',
		text: `Hvad står AIDA for?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Attention, Interest, Desire, Action', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Angrib Ikke Din Agenda',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Act, Invest, Dream, Allow',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Aktiver, Interessenter, Deltagere, Aftaler',
			}),
		],
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'hhxedu-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};