import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Button from '../button/button';
import './info.scss';

const Info = ({ title, text, files, setPopupFileData, subtitle, scenarioId}) => {
	return (
		<div className='Info'>
			<div className={'Info-header ' + scenarioId}>
				<div className={'Info-infoIcon ' + scenarioId}/>
				<div className='Info-titleWrapper'>
					<div className='Info-title'><span>{renderMarkdown(title)}</span></div>
					{subtitle && <div className='Info-subtitle'><span>{renderMarkdown(subtitle)}</span></div>}
				</div> 
			</div>
			<div className='Info-content'>
				<div className='Info-text'>
					{renderMarkdown(text)}
				</div>
				{(files && files.length > 0 && setPopupFileData) && 
					<div className="Info-files">
						{files.map((file, index) => {
							return (
								<Button 
									key={index}
									text={file.btnText}
									classes={['login']}
									onClick={() => {setPopupFileData({type: file.type, fileId: file.id});}}
								/>
							);
						})}
					</div>
				}
			</div>
		</div>
	);
};

Info.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	files: PropTypes.array,
	setPopupFileData: PropTypes.func,
	subtitle: PropTypes.string,
	scenarioId: PropTypes.string.isRequired,
};

export default Info;