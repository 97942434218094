import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {schoolUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getGroupPoints} from 'helpers/game-helper';
import { soundsData } from 'data/sound-data';
import AudioPlayer from 'react-h5-audio-player';
import GroupName from '../group-name/group-name';
import './leaderboard.scss';

const Leaderboard = ({status, gameGroups, setShowLeaderboard, scenarioId, updateGroup}) => {
	const [sortedGroups, setSortedGroups] = useState(null);

	let audioPlayerRef = useRef();

	const getNewSortedList = () => {
		let newList = gameGroups.map((group) => {
			return ({
				id: group.id,
				name: (group.name ? group.name : group.id),
				points: getGroupPoints(group)
			});
		});
		newList = sortArrayByProperty(newList, 'points', 'DESC');
		if (newList.length > 10) newList.splice(10, newList.length - 10);

		return newList;
	};

	// On init
	//	- set css custom variables
	//	- set initial sortedGroups list
	// On unmount clear all timers
	useEffect(() => {
		/* Sort gameGroups by points */
		let newSortedGroups = getNewSortedList();
		setSortedGroups(newSortedGroups);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let newSortedGroups = getNewSortedList();

		// If we have an old list of gameGroups, we go through it to check for changes
		if (sortedGroups) {
			newSortedGroups.forEach((group, index) => {
				const oldGroupIndex = sortedGroups.findIndex((g) => {return g.id === group.id;});
				
				if (!sortedGroups[oldGroupIndex]) {
					return;
				}

				const pointsChanged = sortedGroups[oldGroupIndex].points !== group.points;

				// Groups is new in list, or has moved to a higher position
				if (oldGroupIndex === -1 || (oldGroupIndex > index && pointsChanged)) {
					// New group rank is top 3
					if (index < 3) {
						audioPlayerRef.current.audio.current.play();
					}

					const elementRank = document.getElementById('rank-' + group.id);
					const elementName = document.getElementById('name-' + group.id);
					const elementRankPoints = document.getElementById('rankPoints-' + group.id);
					elementRank.classList.add('animated');
					elementName.classList.add('animated');
					elementRankPoints.classList.add('animated');

					elementRank.addEventListener('animationend', () => {
						elementRank.classList.remove('animated');
						elementName.classList.remove('animated');
						elementRankPoints.classList.remove('animated');
					});
				}

				// Group is in old list, and has new point total
				if (oldGroupIndex !== -1 && pointsChanged) {
					const elementPoints = document.getElementById('points-' + group.id);
					elementPoints.classList.add('animated');

					elementPoints.addEventListener('animationend', () => {
						elementPoints.classList.remove('animated');
					});
				}
			});
		}

		setSortedGroups(newSortedGroups);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameGroups]);

	return (
		<div id='leaderboard' className={'Leaderboard ' + status}>
			<AudioPlayer
				ref={audioPlayerRef}
				muted={false}
				volume={1}
				autoPlay={false}
				src={soundsData.ding}
				style={{display: 'none'}}
			/>
			<div className={'Leaderboard-header ' + scenarioId}>
				<div className="Leaderboard-title"><span>{schoolUiTexts.leaderboard}</span></div>
				{status === 'open' && <div className={'Leaderboard-closeButton ' + scenarioId}
					onClick={() => {setShowLeaderboard(false);}} />}
			</div>
			<div className="Leaderboard-body">
				<div className="Leaderboard-row">
					<div className='Leaderboard-field rank'><span>{schoolUiTexts.rank}</span></div>
					<div className='Leaderboard-field name'><span>{schoolUiTexts.name}</span></div>
					<div className='Leaderboard-field points'><span>{schoolUiTexts.points}</span></div>
				</div>
				{sortedGroups && sortedGroups.map((group, index) => {
					return (
						<div key={group.id} className={'Leaderboard-row ' + scenarioId}>
							{/* GROUP RANK */}
							<div id={'rank-' + group.id}
								className={'Leaderboard-field rank ' + scenarioId}>
								<span>{index + 1 + '.'}</span>
							</div>
							{/* GROUP NAME */}
							<div id={'name-' + group.id} className={'Leaderboard-field name ' + scenarioId}>
								<GroupName 
									layout="leaderboard" 
									groupId={group.id}
									currentGroupName={group.name}
									updateGroup={updateGroup}
								/>
							</div>
							{/* GROUP POINTS */}
							<div id={'rankPoints-' + group.id}
								className={'Leaderboard-field points ' + scenarioId}>
								<div id={'points-' + group.id} className='Leaderboard-pointsField'>
									<span>{group.points}</span>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Leaderboard.propTypes = {
	status: PropTypes.string.isRequired,
	gameGroups: PropTypes.array.isRequired,
	setShowLeaderboard: PropTypes.func,
	scenarioId: PropTypes.string.isRequired,
	updateGroup: PropTypes.func.isRequired
};

export default Leaderboard;