import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';
import { renderMarkdown } from 'helpers/text-helper';
import { gameUiTexts } from 'data/ui-texts';
import './popup-assignment-hint.scss';

const PopupAssignmentHint = ({title, text, image = null, togglePopup}) => {
	return (
		<div className='PopupAssignmentHint' onClick={() => {togglePopup(false);}}>
			<div className='PopupAssignmentHint-content' onClick={(e) => {e.stopPropagation();}}>
				<div className='PopupAssignmentHint-header'>
					<span>{title}</span>
				</div>
				<div className='PopupAssignmentHint-body'>
					{image && <div className={'PopupAssignmentHint-image ' + image}/>}
					<div className='PopupAssignmentHint-text'>
						<span>{(text ? renderMarkdown(text) : '')}</span>
					</div>
					<div className='PopupAssignmentHint-button'>
						<Button
							text={gameUiTexts.ok}
							classes={['blueRounded']}
							onClick={() => {togglePopup(false);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupAssignmentHint.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	image: PropTypes.string,
	togglePopup: PropTypes.func.isRequired,
};

export default PopupAssignmentHint;