import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import { schoolUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './popup-delete-game.scss';

const PopupDeleteGame = ({ gameId, games, deleteGame, closePopup }) => {
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);


	let popupText = JSON.parse(JSON.stringify(schoolUiTexts.deleteGamePopup.text));
	const game = games.find((g) => {return g.id === gameId;});
	if (game) {
		popupText = popupText.replace('%game%', game.name);
	}

	/**
	 * Delete game
	 * @returns 
	 */
	const handleDeleteGame = () => {
		if (isLoading) return;
		setIsLoading(true);
		deleteGame(gameId).then(() => {
			setIsLoading(false);
			closePopup();
		}).catch((error) => {
			console.error(error);
			setIsLoading(false);
		});
	};

	return (
		<div onClick={() => {closePopup();}} className="PopupDeleteGame">
			<div onClick={(e) => {e.stopPropagation();}} className="PopupDeleteGame-content">
				<div className='PopupDeleteGame-title'><span>{schoolUiTexts.deleteGamePopup.title}</span></div>
				<div className='PopupDeleteGame-text'>
					{renderMarkdown(popupText)}
				</div>
				<div className='PopupDeleteGame-buttons'>
					<div className='PopupDeleteGame-confirmBtn'>
						<Button 
							isLoading={isLoading}
							classes={['login']}
							text={schoolUiTexts.deleteGamePopup.confirmBtn} 
							onClick={() => {handleDeleteGame();}}
						/>
					</div>
					<div className='PopupDeleteGame-cancelBtn'>
						<Button 
							classes={['login']}
							text={schoolUiTexts.deleteGamePopup.cancelBtn}
							onClick={() => {closePopup();}}
						/>
					</div>
				</div>
			</div> 
		</div>
	);
};

PopupDeleteGame.propTypes = {
	gameId: PropTypes.string.isRequired,
	games: PropTypes.array.isRequired,
	deleteGame: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default PopupDeleteGame;
