/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBmNVVh9vL6UMq8UKaRT7Txr2UIU4_rPiM',
		authDomain: 'cgl-karriereindsatsen-n-test.firebaseapp.com',
		projectId: 'cgl-karriereindsatsen-n-test',
		storageBucket: 'cgl-karriereindsatsen-n-test.appspot.com',
		messagingSenderId: '759393298332',
		appId: '1:759393298332:web:62be6e06e732f7ed784a59'
	};
} else if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDCyepgr0mLMAeZFwvH_fPRD93ni4cCacY',
		authDomain: 'cgl-karriereindsatsen-n-prod.firebaseapp.com',
		projectId: 'cgl-karriereindsatsen-n-prod',
		storageBucket: 'cgl-karriereindsatsen-n-prod.appspot.com',
		messagingSenderId: '1027689926455',
		appId: '1:1027689926455:web:4aa696a7feec72051714b2'
	  };
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;