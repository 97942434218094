import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { schoolUiTexts } from 'data/ui-texts';
import { soundsData } from 'data/sound-data';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import PopupGameStatusWarning from 'components/ui/popup-game-status-warning/popup-game-status-warning';
import GameCode from 'components/ui/game-code/game-code';
import GroupName from 'components/ui/group-name/group-name';
import './game-ready.scss';

const GameReady = (props) => {
	/* Props */
	const {
		game, 
		schoolData, 
		gameGroups, 
		updateGame, 
		handleGoToPage, 
		updateBackground, 
		handlePlayMusic, 
		updateGroup
	} = props;
	
	
	/* Track if is starting (updating) game */
	const [isStartingGame, setIsStartingGame] = useState(false);

	/* Track if show/hide warning popup */
	const [showStartGameWarningPopup, setShowStartGameWarningPopup] = useState(false);

	/* Track the amount of placeholders to display */
	const placeholders =  (gameGroups && gameGroups.length < 12) ? [...Array(12 - gameGroups.length)] : [];

	/**
	 * Start game
	 */
	const handleStartGame = () => {
		if (gameGroups.length === 0) {
			/* No groups logged in, show warning */
			setShowStartGameWarningPopup(true);
		} else {
			/* 1 or more groups logged in, update game status */
			setIsStartingGame(true);
			const startTime = Date.now();
			updateGame({status: 'open', startTime}).catch((error) => {
				console.error(error);
				setIsStartingGame(false);
			});
		}
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		// Set background image id
		updateBackground('school', game.scenarioId);
		handlePlayMusic(true, soundsData.login);

		return () => {
			handlePlayMusic(false, null);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	
	return (
		<div className="GameReady">
			{/* Logo */}
			<div className="GameReady-logo" onClick={() => {handleGoToPage('landing');}} >
				<Logo scenarioId={game.scenarioId} />
			</div>

			{/* School name */}
			<div className="GameReady-schoolName">
				<span>{schoolData.name}</span>
			</div>

			{/* Game code */}
			<GameCode page="game-ready" game={game}/>
			
			{/* Groups title */}
			<div className="GameReady-title">
				<span>{schoolUiTexts.gameReadyPage.group}:</span>
			</div>

			{/* Logged in groups */}
			<div className="GameReady-groups">
				{gameGroups.map((group, index) => {
					return (
						<div key={index} className={'GameReady-group ' + (group.isPlaying ? 'active' : '')}>
							<div className={'GameReady-groupName ' + game.scenarioId}>
								<GroupName 
									layout="ready" 
									groupId={group.id}
									currentGroupName={group.name ? group.name : group.id} 
									updateGroup={updateGroup}
								/>
							</div>
						</div>
					);
				})}
				{placeholders.map((item, index) => {
					return (
						<div key={index} className={'GameReady-group '}></div>
					);
				})}
			</div>


			{/* Start game btn */}
			<div className="GameReady-startBtn">
				<Button 
					isLoading={isStartingGame}
					classes={['blueLight', game.scenarioId]}
					text={schoolUiTexts.startGameBtn}
					onClick={handleStartGame}
				/>
			</div>

			{/* Back to landing page btn */}
			<div className="GameReady-backBtn">
				<Button 
					classes={['blueLight', game.scenarioId, 'backBtn', 'responsive']}
					text={schoolUiTexts.back}
					onClick={() => {handleGoToPage('landing');}}
				/>
			</div>

			{/* Warning popup (no groups logged in) */}
			{showStartGameWarningPopup &&
				<PopupGameStatusWarning 
					newStatus='open'
					additionalGameState={{startTime: Date.now()}}
					updateGame={updateGame}
					closePopup={() => {setShowStartGameWarningPopup(false);}}
				/>}
		</div>
	);
};

GameReady.propTypes = {
	game: PropTypes.object.isRequired,
	schoolData: PropTypes.object.isRequired,
	gameGroups: PropTypes.array.isRequired,
	updateGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired
};

export default GameReady;
