import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {errorUiTexts, loginUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import LandingPageSchool from 'components/landing-page-school/landing-page-school';
import Admin from 'components/admin/admin';
import NoAccessPage from 'components/no-access-page/no-access-page';
import GameReady from 'components/game-ready/game-ready';
import GameOpen from 'components/game-open/game-open';
import GameDone from 'components/game-done/game-done';

class SchoolController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'landing', // landing, game, admin
			loadingGameId: null,
			games: [],
			gameGroups: []
		};
		this.timeout = null;
		this.unsubscribeGames = null;
		this.unsubscribeGameGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get school games */
		this.subscribeToGames().then(() => {
			this.setState({isLoading: false});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe from game & game groups */
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		if (this.unsubscribeGameGroups !== null) this.unsubscribeGames();
	};

	/**
	 * Subscribe to all games of school
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName)
				.where('schoolId', '==', this.props.userId)
				.where('scenarioId', '==', this.props.scenarioId);			
			this.unsubscribeGames = query.onSnapshot((querySnapshot) => {
				let games = [];
				querySnapshot.forEach((doc) => {games.push({id: doc.id, ...doc.data()});});
				this.setState({games: games}, () => {
					resolve({status: 'success'});
				});
			}, (error) => {
				console.error('could not get games: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to all groups selected game
	 * @param {string} gameId
	 */
	subscribeToGameGroups = (gameId) => {
		return new Promise((resolve) => {
			let db = firebase.firestore();
			if (this.unsubscribeGameGroups !== null) this.unsubscribeGameGroups();

			const gameIds = this.state.games.map((g) => {return g.id;});

			this.unsubscribeGameGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					const gameGroups = [];
					querySnapshot.forEach((gameGroup) => {
						if (gameGroup.data() && gameIds.includes(gameGroup.data().gameId)) {
							gameGroups.push({id: gameGroup.id, ...gameGroup.data()});
						}
					});
					this.setState({gameGroups});
					resolve({status: 'success'});
				},
				(error) => {
					console.error('could not get groups: ', error);
					resolve({status: 'error', error});
				}
			);
		});
	};

	/**
	 * Go to page (landing / game / admin)
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		if (this.state.page === 'game' && page !== 'game') {
			/* Leaving game, unsubscribe game groups */
			this.unsubscribeGameGroups();
			this.setState({page, gameGroups: [], gameId: null});	
		} else {
			this.setState({page});
		}
		
	};

	/**
	 * Go to game
	 * @param {string} gameId 
	 * @returns 
	 */
	goToGame = (gameId) => {
		if (this.state.loadingGameId) return;
		
		const gameData = this.state.games.find((game) => {return game.id === gameId;});
		if (gameData) {
			this.setState({loadingGameId: gameId}, () => {
				this.subscribeToGameGroups(gameId).then((response) => {
					if (response.status === 'success') {
						this.setState({gameId, page: 'game', loadingGameId: null});
					} else {
						this.setState({loadingGameId: null});
						console.error(response.error);
					}
				}); 
			});
		} else {
			this.setState({loadErrMsg: errorUiTexts.noGameDataDatabase});
		}
	};

	/**
	 * Create game
	 */
	createGame = (gameName, studentCount) => {
		return new Promise((resolve) => {
			apiHelper('school/create-game', {
				schoolId: this.props.userId,
				gameName: gameName,
				studentCount: studentCount,
				scenarioId: this.props.scenarioId
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Delete game
	 * @param {string} gameId 
	 * @returns 
	 */
	deleteGame = (gameId) => {
		return new Promise((resolve) => {
			apiHelper('school/delete-game', {gameId, gameDurationMinutes: appConfig.gameDurationMinutes}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Update game
	 * @param {object} updates
	 * @param {string} id
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		const gameId = (id ? id : this.state.gameId);
		const db = firebase.firestore();
		const gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};


	/**
	 * Update group
	 * @param {string} groupId 
	 * @param {object} updates 
	 * @returns {promise}
	 */
	updateGroup = (groupId, updates) => {
		const db = firebase.firestore();
		const groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};


	/**
	 * Render component
	 */
	render = () => {
		/* Get school data */
		const schoolData = this.props.schools.find((s) => {return s.id === this.props.userId;});

		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={loginUiTexts.loadingGame}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};
		/* Admin */
		if (this.state.page === 'admin') {
			return (
				<Admin 
					scenarioId={this.props.scenarioId}
					updateBackground={this.props.updateBackground}
					handleGoToPage={this.handleGoToPage}
				/>
			);
		}

		/* School does not have access to selected scenario */
		if (!schoolData || !schoolData.scenarioIds || !schoolData.scenarioIds.includes(this.props.scenarioId)) {
			return (
				<NoAccessPage 
					scenarioId={this.props.scenarioId}
					schoolData={schoolData}
					updateBackground={this.props.updateBackground}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		/* Game */
		if (
			this.state.page === 'game' && 
			this.state.gameId &&
			this.state.games.some((game) => {return game.id === this.state.gameId;})
		) {
			/* Get game data */
			const game = this.state.games.find((game) => {return game.id === this.state.gameId;});
			
			/* Game status: done */
			if (game.status === 'done') {
				return (
					<GameDone
						scenarioId={this.props.scenarioId}
						game={game}
						gameGroups={this.state.gameGroups}
						handleGoToPage={this.handleGoToPage}
						updateBackground={this.props.updateBackground}
						handlePlayMusic={this.props.handlePlayMusic}
						updateGroup={this.updateGroup}
					/>
				);
			}
			/* Game status: open */
			if (game.status === 'open') {
				return (
					<GameOpen 
						scenarioId={this.props.scenarioId}
						schoolData={schoolData}
						game={game}
						gameGroups={this.state.gameGroups}
						updateGame={this.updateGame}
						handleGoToPage={this.handleGoToPage}
						updateBackground={this.props.updateBackground}
						handlePlayMusic={this.props.handlePlayMusic}
						updateGroup={this.updateGroup}
					/>
				);
			}
			/* Game status: ready */
			return (
				<GameReady 
					game={game}
					schoolData={schoolData}
					gameGroups={this.state.gameGroups}
					updateGame={this.updateGame}
					handleGoToPage={this.handleGoToPage}
					updateBackground={this.props.updateBackground}
					handlePlayMusic={this.props.handlePlayMusic}
					updateGroup={this.updateGroup}
				/>
			);
		}


		/* Landing page */
		return (
			<LandingPageSchool 
				scenarioId={this.props.scenarioId}
				loadingGameId={this.state.loadingGameId}
				schoolData={schoolData}
				games={this.state.games}
				goToGame={this.goToGame}
				createGame={this.createGame}
				deleteGame={this.deleteGame}
				handleLogout={this.props.handleLogout}
				handleGoToPage={this.handleGoToPage}
				updateBackground={this.props.updateBackground}
			/>
		);
	};
}

SchoolController.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	schools: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default SchoolController;
