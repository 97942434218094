const taskTemplates = require('../../task-templates');

const tasksData = [
	/* case 1 */
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		taskId: 'economy-sort-inform-skat',
		title: 'Opgave 1A',
		text: `Hvilke af disse tror i at virksomheder skal oplyse til SKAT?`,
		hintText: `Har I overvejet at kigge på Virk eller SKAT's hjemmeside?`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'inform', 
				title: `Skal oplyses`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'not-inform', 
				title: `Skal ikke oplyses`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Moms`,
				categoryIds: ['inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Omsætning`,
				categoryIds: ['inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Privatforbrug`,
				categoryIds: ['inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Rabat fra leverandører`,
				categoryIds: ['inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Antal besøgende kunder på hjemmesiden`,
				categoryIds: ['not-inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: `Lager-adresse`,
				categoryIds: ['not-inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, 
				text: `Antal kunder`,
				categoryIds: ['not-inform']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 8, 
				text: `Antal betalte fakturaer`,
				categoryIds: ['not-inform']
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1.5,
		taskId: 'economy-order-skat-info',
		lockedById: 'economy-sort-inform-skat',
		title: 'Opgave 1B',
		text: `Følgende oplysninger skal gives til skat - men hvad er vigtigst? Sæt  i korrekt rækkefølge.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: `CVR nummer`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: `Virksomhedsform`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: `Omsætning`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: `Ledelsesberetning`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, 
				text: `Ansattes navne`,
				correctOrderNumbers: [5]
			})
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1A',
		taskId: 'economy-input-answer-afkast',
		text: `Kig på nøgletallene. Brug dem til at udregene afkastningsgraden.
		<br />Skriv svaret med to decimaler. `,
		answer: 1231.25,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Afkastningsgraden (AG) = Resultat før renter / gns. aktiver * 100',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.1,
		title: 'Opgave 1B',
		taskId: 'economy-input-answer-overskudsgrad',
		lockedById: 'economy-input-answer-afkast',
		text: `Kig på nøgletallene. Brug dem til at udregene overskudsgraden.
		<br />Skriv svaret med to decimaler. `,
		answer: 103.89,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Overskudsgrad (OG) = Resultat af primær drift x 100 / Omsætning',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.2,
		title: 'Opgave 1C',
		taskId: 'economy-input-answer-omsaetningshastighed',
		lockedById: 'economy-input-answer-overskudsgrad',
		text: `Kig på nøgletallene. Brug dem til at udregene aktivernes omsætningshastighed.
		<br />Skriv svaret med to decimaler. `,
		answer: 20.35,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Aktivernes omsætningshastighed (AOH) = Nettoomsætning / Gennemsnitlige aktiver',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.3,
		title: 'Opgave 1D',
		taskId: 'economy-input-answer-gaeldsrente',
		lockedById: 'economy-input-answer-omsaetningshastighed',
		text: `Kig på nøgletallene. Brug dem til at udregene gældsrenten.
		<br />Skriv svaret med to decimaler. `,
		answer: 59.22,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Gældsrente = Finansielle omkostninger x 100 / Gennemsnitlige gældsforpligtelser',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.4,
		title: 'Opgave 1E',
		taskId: 'economy-input-answer-egenkapital',
		lockedById: 'economy-input-answer-gaeldsrente',
		text: `Kig på nøgletallene. Brug dem til at udregene egenkapitalens forrentning.
		<br />Skriv svaret med to decimaler. `,
		answer: 4652.78,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Egenkapitalens forrentning = Resultat før skat x 100 / Gennemsnitlig egenkapital',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.5,
		title: 'Opgave 1F',
		taskId: 'economy-input-answer-daekningsgrad',
		lockedById: 'economy-input-answer-egenkapital',
		text: `Kig på nøgletallene. Brug dem til at udregene dækningsgraden.
		<br />Skriv svaret med to decimaler. `,
		answer: 6.32,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Dækningsgrad (DG) = Dækningsbidrag × 100 / Nettoomsætning',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.6,
		title: 'Opgave 1G',
		taskId: 'economy-input-answer-gennemsnitslager',
		lockedById: 'economy-input-answer-daekningsgrad',
		text: `Kig på nøgletallene. Brug dem til at udregene gennemsnitslager.`,
		answer: 1200000,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Gennemsnitslager i kr. = Gennemsnitslager i enheder × Kostpris',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1.7,
		title: 'Opgave 1H',
		taskId: 'economy-input-answer-lagersevicegrad',
		lockedById: 'economy-input-answer-gennemsnitslager',
		text: `Kig på nøgletallene. Brug dem til at udregene lagerservicegraden.
		<br />Skriv svaret med to decimaler.`,
		answer: 61.35,
		unit: '',
		hintText: `Du kan både finde svaret på Google og i vedhæftede bilag.`,
		popupText: 'Lagerservicegrad = Leveret antal enheder x 100 / Bestilte antal enheder',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		taskId: 'economy-order-skat-equation-index-number',
		title: 'Opgave 2',
		text: `En medarbejder fra indkøb i Roll and Ride har brug for hjælp til hvordan man beregner indextal. Sæt formlen i den rigtige rækkefølge.`,
		hintText: 'Kig i bilag, og sæt det rigtig sammen.',
		files: [
			{
				id: 'economy-numbers',
				type: 'image',
				btnText: 'Se nøgletal',
			}		
		],
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: `Årstal`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: `Gange`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: `100`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: `Divideret med`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, 
				text: `Basisårets tal`,
				correctOrderNumbers: [5]
			})
		]
	}),
	/* case 3 */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		taskId: 'economy-order-buy-newly-opened-store',
		title: 'Opgave 1',
		text: `Hvad er vigtigst at have i en nyåbnet Roll and Ride butik? Sæt tingene i rækkefølge fra mest til mindst vigtig. `,
		hintText: 'Tænk over, at vi sælger tøj.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: `Inventar`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: `Kasseaparat`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: `Butiks computer`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: `Moppe`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, 
				text: `Møde lokale for medarbejder`,
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, 
				text: `Snacks`,
				correctOrderNumbers: [6]
			}),
			
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, 
				text: `Soundbox`,
				correctOrderNumbers: [7]
			})
		]
	}),
	/* case 4 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		title: 'Opgave 1A',
		taskId: 'economy-multiple-choice-buyer-tasks',
		text: `Hvilke opgaver har vores medarbejdere i indkøbsafdelingen?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Indkøber virksomhedens produkter uden yderligere ansvar'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Indkøber virksomhedens produkter markedsfører dem på nettet på det rigtige tidspunkter'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Indkøber de rigtige produkter, i den rette mængde, til den rigtige tid og til den rigtige pris', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Indkøber og sælger produkter over nettet'
			})
		],
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1.5,
		title: 'Opgave 1B',
		taskId: 'economy-paper-doll-shopper-items',
		lockedById: 'economy-multiple-choice-buyer-tasks',
		layout: 'economy-shopper-items',
		avatarId: 'avatar-3',
		text: `Hvilke 4 ting benytter en indkøber blandt andet?
		</br> Vælg de vigtigste ting. Tryk OK, når I mener, I har dem alle.`,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'office-chair', title: 'Kontorstol', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'shirt-purple', title: 'Trøje'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron', title: 'Forklæde'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'laptop', title: 'Computer', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'phone', title: 'Telefon', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'note-book', title: 'Notesblok', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'sunglasses', title: 'Briller'
			}),

		]
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'hhxedu-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};