import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import './audio.scss';

const Audio = ({audioSrc, isPlaying}) => {
	/* Ref to audio player */
	const audioPlayerRef = useRef();

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Get ref to current audio player */
		const newAudioPlayerRef = (audioPlayerRef ? audioPlayerRef.current : null);
		if (newAudioPlayerRef && newAudioPlayerRef.audio && newAudioPlayerRef.audio.current) {
			if (isPlaying) {
				newAudioPlayerRef.audio.current.play();
			} else {
				newAudioPlayerRef.audio.current.pause();
			}
		}
		/* Component will ummount */
		return () => {
			if (newAudioPlayerRef && newAudioPlayerRef.audio && newAudioPlayerRef.audio.current) {
				/* Stop playback */
				newAudioPlayerRef.audio.current.pause();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isPlaying) {
			audioPlayerRef.current.audio.current.play();
		} else {
			audioPlayerRef.current.audio.current.pause();
		}
	}, [isPlaying]);

	/* No audio src, return null */
	if (!audioSrc) return null;

	/* Return audio player */
	return (
		<div className="Audio">
			<AudioPlayer
				ref={audioPlayerRef}
				muted={false}
				preload="metadata"
				volume={1}
				autoPlay={true}
				loop={true}
				src={audioSrc}
				width="100%"
				height="100%"
				style={{display: 'none'}}
			/>
		</div>
	);
};

Audio.propTypes = {
	audioSrc: PropTypes.string.isRequired,
	isPlaying: PropTypes.bool.isRequired,
};



export default Audio;