/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		title: '',
		type: 'multiple-choice',
		isDilemma: false,
		subtype: null, // null, images
		multiline: 'auto', // auto, on, off
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		layout: null, // used if subtype is 'image'
		shuffleOptions: true,
		lockedById: null,
		image: null,
		imageSolved: null,
		files: null,
		text: '',
		options: [],
		maxPoints: null,
		isDilemma: false,
		numberOfAnswersToSelect: 0,
		hintText: null,
		hintImage: null, // Class name for hint image
		popupText: null
	},
	optionTemplate: {
		text: '', 
		isCorrect: false,
		points: 0
	}
};


/* Sort */
const sortTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		type: 'sort',
		subtype: null, // null, images
		layout: '',
		text: '',
		image: null,
		categories: [],
		items: [],
		maxPoints: null,
		hintText: null,
		popupText: null
	},
	categoryTemplate: {
		type: 'title', // title, image
		title: null,
		text: null,
	},
	itemTemplate: {
		type: 'text', // text, image
		text: null,
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		type: 'order',
		image: null,
		text: '',
		items: [],
		maxPoints: null,
		hintText: null,
		popupText: null
	},
	itemTemplate: {
		text: '',
		correctOrderNumbers: []
	}
};

/* Input-answer */
const inputAnswerTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		type: 'input-answer',
		subtype: null, // null, range
		title: '',
		text: '',
		image: null,
		answer: null, // number
		unit: '',
		range: null, // null {min, max}
		maxPoints: null,
		hintText: null,
		popupText: null
	}
};

/* Paper doll */
const paperDollTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		type: 'paper-doll',
		avatarId: 'avatar-1',
		layout: null,
		taskId: null, // e.g. 'it-paper-doll-items'
		items: [], // should be ordered after intended z-index (small to large)
		hintText: null,
		popupText: null
	},
	itemTemplate: {
		id: null,
		type: 'default', // default (selected image is same as in panel), alternative (suffix -b)
		isCorrect: false,
		title: '',
	}
};


/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		type: 'spot-errors',
		doneWhenAllErrorsSpotted: false,
		layout: null,
		text: null,
		errors: [],
		hintText: null,
		popupText: null
	},
	errorTemplate: {
		effects: []
	}
};

/* Pairs */
const pairsTemplate = {
	mainTemplate: {
		caseId: 0,
		taskNumber: null,
		type: 'pairs',
		text: null,
		columnAItems: [],
		columnBItems: [],
		hintText: null,
		popupText: null
	},
	itemTemplate: {
		id: null,
		type: 'text',
		text: null,
		image: null,
		connectedToItemId: null
	}
}

export {
	multipleChoiceTemplate,
	sortTemplate,
	orderTemplate,
	inputAnswerTemplate,
	paperDollTemplate,
	spotErrorsTemplate,
	pairsTemplate
};
