import React from 'react';
import PropTypes from 'prop-types';
import './background.scss';

const Background = ({ background }) => {
	let backgroundClass = null;

	if (background && background.scenarioId === 'game1') {
		/* Game 1 backgrounds */
		backgroundClass = 'novo ';
		switch (background.backgroundId) {
		case 'office':
			backgroundClass += 'office';
			break;
		case 'research':
			backgroundClass += 'research';
			break;
		case 'it':
			backgroundClass += 'it';
			break;
		case 'build':
			backgroundClass += 'build';
			break;
		case 'prep':
			backgroundClass += 'prep';
			break;
		case 'design':
			backgroundClass += 'design';
			break;
		case 'production':
			backgroundClass += 'production';
			break;
		case 'login':
			backgroundClass += 'login';
			break;
		case 'school':
			backgroundClass += 'school';
			break;		
		default:
			break;
		}
	} else if (background && background.scenarioId === 'game2') {
		/* Game 2 backgrounds */
		backgroundClass = 'goodsellers ';
		switch (background.backgroundId) {
		case 'office':
			backgroundClass += 'office';
			break;
		case 'marketing':
			backgroundClass += 'marketing';
			break;
		case 'it':
			backgroundClass += 'it';
			break;
		case 'management':
			backgroundClass += 'management';
			break;
		case 'sales':
			backgroundClass += 'sales';
			break;
		case 'login':
			backgroundClass += 'login';
			break;
		case 'school':
			backgroundClass += 'school';
			break;
		case 'economy':
			backgroundClass += 'economy';
			break;
		default:
			break;
		}
	}
	
	return (
		<div className='Background'>
			<div className={'Background-' + backgroundClass} />
		</div>
	);
};

Background.propTypes = {
	background: PropTypes.object.isRequired,
};

export default Background;