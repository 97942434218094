import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {schoolUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './popup-game-status-warning.scss';

const PopupGameStatusWarning = ({newStatus, updateGame, closePopup, additionalGameState}) => {
	/* Track if updating game status */
	const [isUpdatingGame, setIsUpdatingGame] = useState(false);

	/**
	 * Restart game
	 * @returns 
	 */
	const handleUpdateGame = () => {
		if (isUpdatingGame) return;
		setIsUpdatingGame(true);
		const gameUpdate = additionalGameState ? {status: newStatus, ...additionalGameState} : {status: newStatus};
		updateGame(gameUpdate).then(() => {
			setIsUpdatingGame(false);
			closePopup();
		}).catch((error) => {
			console.error(error);
			setIsUpdatingGame(false);
		});

	};

	/* Get popup data (depends on status) */
	const popupData = (newStatus === 'open'
		? schoolUiTexts.noGroupsWarning
		: schoolUiTexts.closeGameWarning
	);

	return (
		<div onClick={() => {closePopup();}} className="PopupGameStatusWarning">
			<div onClick={(e) => {e.stopPropagation();}} className="PopupGameStatusWarning-content">
				<div className='PopupGameStatusWarning-title'><span>{popupData.title}</span></div>
				<div className='PopupGameStatusWarning-text'>
					{renderMarkdown(popupData.text)}
				</div>
				<div className='PopupGameStatusWarning-buttons'>
					<div className='PopupGameStatusWarning-confirmBtn'>
						<Button 
							isLoading={isUpdatingGame}
							classes={['login']}
							text={popupData.confirmBtn} 
							onClick={() => {handleUpdateGame();}}
						/>
					</div>
					<div className='PopupGameStatusWarning-cancelBtn'>
						<Button 
							classes={['login']}
							text={popupData.cancelBtn}
							onClick={() => {closePopup();}}
						/>
					</div>
				</div>
			</div> 
		</div>
	);
};

PopupGameStatusWarning.propTypes = {
	newStatus: PropTypes.string.isRequired,
	updateGame: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	additionalGameState: PropTypes.object,
};

export default PopupGameStatusWarning;
