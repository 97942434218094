import React from 'react';
import PropTypes from 'prop-types';
import './popup-case-file.scss';

const PopupCaseFile = ({popupData, setPopupData }) => {
	return (
		<div 
			className={'PopupCaseFile' + (popupData.type ? ' ' + popupData.type : '')} 
			onClick={() => {setPopupData(null);}}
		>
			<div onClick={(e) => {e.stopPropagation();}} className="PopupCaseFile-content">
				{popupData.type === 'image' && 
					<div className={'PopupCaseFile-image ' + popupData.fileId}/>}
				<div className="PopupCaseFile-closeBtn" onClick={() => {setPopupData(null);}} />
			</div> 
		</div>
	);
};

PopupCaseFile.propTypes = {
	popupData: PropTypes.object.isRequired,
	setPopupData: PropTypes.func.isRequired,
};

export default PopupCaseFile;
