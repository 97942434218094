import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import {renderMarkdown} from 'helpers/text-helper';
import './sort-dnd-preview.scss';

const SortDndPreview = ({layout, itemsData}) => {
	const {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemData = itemsData.find((i) => {return i.id === item.id;});

	if (!itemData) return null;

	const text = (itemData.type === 'text' && itemData.text
		? renderMarkdown(itemData.text)
		: null
	);

	return (
		<div 
			className={'SortDndDragLayer'} 
			style={style}
		>
			<div className={'SortDndDragLayer-item item-' + itemData.id + ' ' + itemData.type 
				+ (layout ? ' ' + layout : '')}>
				{text}
			</div>
		</div>
	);
};

SortDndPreview.propTypes = {
	layout: PropTypes.string,
	itemsData: PropTypes.array.isRequired
};

export default SortDndPreview;