import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import PropTypes from 'prop-types';
import apiHelper from 'helpers/api-helper';
import { errorUiTexts } from 'data/ui-texts';
import appConfig from 'config/app.config';
import LoginGroup from './login-group';


class LoginGroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			showCreateNewGroup: false,
			code: null,
			groups: null,
			groupId: null,
			groupName: null,
			feedback: null
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			feedback: null
		});
	};


	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event 
	 */
	handleFindGame = (event = null) => {
		if (event) event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({feedback: errorUiTexts.emptyFields});
			return;
		}

		const lowercaseCode = code.toLowerCase();
		this.setState({isLoading: true}, () => {
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).doc(lowercaseCode).get().then((doc) => {
				if (doc.exists) {
					/* Game exists, get groups */
					db.collection(appConfig.groupsDbName).where('gameId', '==', lowercaseCode).get().then((docs) => {
						let groups = [];
						docs.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
						this.setState({groups: groups, isLoading: false, showCreateNewGroup: (groups.length === 0)});
					}).catch((error) => {
						console.error(error);
						const msg = errorUiTexts.unknownError;
						this.setState({feedback: msg, isLoading: false});		
					});
				} else {
					/* Game does not exist */
					this.setState({isLoading: false, feedback: errorUiTexts.noGameCodeMatch});
				}
			}).catch((error) => {
				console.error(error);
				this.setState({isLoading: false, feedback: errorUiTexts.unknownError});
			});	
		});
	};

	/**
	 * Hide / show "create new group"
	 */
	 toggleShowCreateNewGroup = () => {
		const showCreateNewGroup = !this.state.showCreateNewGroup;
		this.setState({showCreateNewGroup: showCreateNewGroup, groupName: null});
	};

	/**
	 * Create new group and get login token
	 * @param {string} groupName 
	 * @param {object} event
	 * @returns 
	 */
	 handleCreateGroup = (groupName, event) => {
		if (event) event.preventDefault();

		/* Group name too short */
		if (!groupName || groupName.length < appConfig.groupNameMinLength) {
			this.setState({feedback: errorUiTexts.groupNameTooShort});
			return;
		}
		/* Group name too long */
		if (groupName.length > appConfig.groupNameMaxLength) {
			this.setState({feedback: errorUiTexts.groupNameTooLong});
			return;
		}

		this.setState({isLoading: true}, () => {
			const lowercaseCode = this.state.code.toLowerCase();
			apiHelper('group/create-group', {gameCode: lowercaseCode, groupName: groupName})
				.then((response)=>{
					if (response.status === 'success' && response.token) {
						this.loginWithToken(response.token);
					} else {
						if (response.error === 'group-name-already-exists') {
							this.setState({
								isLoading: false, 
								feedback: errorUiTexts.groupNameAlreadyExists
							});
						} else {
							this.setState({isLoading: false, feedback: response.error});
						}	
					}
				})
				.catch((rejection) => {
					console.error(rejection);
					this.setState({
						isLoading: false, 
						feedback: errorUiTexts.unknownError
					});
				});
		});
	 };

	/**
	 * Get a login token for specific group
	 * Call firebase auth to sign in with that token.
	 * @param {number} groupId 
	 */
	handleGetLoginToken = (groupId) => {
		this.setState({isLoading: true, groupId: groupId}, () => {
			apiHelper('group/join-game', {groupId: groupId}).then(
				(response)=>{
					if (response.status === 'success' && response.token) {
						this.loginWithToken(response.token);
					} else {
						console.error(response);
						this.setState({
							isLoading: false,
							feedback: errorUiTexts.unknownError
						});
					}
				},
				(rejection) => {
					console.error('rejection: ', rejection);
					this.setState({
						isLoading: false,
						feedback: errorUiTexts.unknownError
					});
				}
			);
		});
	};


	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
			// All okay, should trigger the authStateChanged in LoginController
			})
			.catch((error) => {
				console.error('sign in error', error);
				this.setState({
					feedback: errorUiTexts.unknownError,
					isLoading: false,
					groupId: null,
					groupName: null
				});
			});
	};

	render = () => {
		return (
			<LoginGroup
				isLoading={this.state.isLoading}
				showCreateNewGroup={this.state.showCreateNewGroup}
				code={this.state.code}
				groups={this.state.groups}
				groupId={this.state.groupId}
				groupName={this.state.groupName}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				toggleShowCreateNewGroup={this.toggleShowCreateNewGroup}
				handleCreateGroup={this.handleCreateGroup}
				handleGetLoginToken={this.handleGetLoginToken}
				scenarioId={this.props.scenarioId}
			/>
		);
	};
}

LoginGroupController.propTypes = {
	scenarioId: PropTypes.string.isRequired,
};

export default LoginGroupController;