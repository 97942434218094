import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {renderMarkdown} from 'helpers/text-helper';
import SortDndContainer from './sort-dnd-container';
import SortDndItem from './sort-dnd-item';
import SortDndPreview from './sort-dnd-preview';
import './sort.scss';

const Sort = ({isCompleted, playerTaskData, taskData, handleUpdateTask, handleCompleteTask, scenarioId}) => {
	/* Get items to be sorted */
	const getItems = () => {
		/* Get items from group data or data file */
		let items = (playerTaskData && playerTaskData.sortedItems
			? JSON.parse(JSON.stringify(playerTaskData.sortedItems))
			: taskData.items.map((item) => {return {itemId: item.id, containerId: null};})
		);

		/* Shuffle items and return */
		return shuffleArray(items);
	};

	const getErrors = () => {
		let errors = 0;
		if (playerTaskData && playerTaskData.errors) {
			errors = playerTaskData.errors;
		}
		return errors;
	};

	/* Track sorted items & errors */
	const [sortedItems, setSortedItems] = useState(getItems());
	const [errors, setErrors] = useState(0);


	/* Update sorted items if new task */
	useEffect(() => {
		setSortedItems(getItems());
		setErrors(getErrors());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		const item = sortedItems.find((item) => {return item.itemId === itemId;});
		return (item ? item.containerId : null);
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, containerId) => {
		/* Move item */
		let newSortedItems = JSON.parse(JSON.stringify(sortedItems));
		const itemIndex = newSortedItems.findIndex((item) => {return item.itemId === itemId;});
		newSortedItems[itemIndex].containerId = containerId;
		setSortedItems(newSortedItems);

		/* Check if it is an error */
		let newErrors = errors;
		const itemData = taskData.items.find((itemData) => {return itemData.id === itemId;});
		const isCorrect = (itemData.categoryIds.indexOf(containerId) >= 0);
		if (!isCorrect) {
			newErrors += 1;
			setErrors(newErrors);
		}

		/* Check if task is completed: all items are placed into a category && it is the right category */
		if (
			newSortedItems.every((item) => {return item.containerId !== null;}) && 
			newSortedItems.every((item) => {
				const itemData = taskData.items.find((itemData) => {return itemData.id === item.itemId;});
				return (itemData.categoryIds.indexOf(item.containerId) >= 0);
			})
		) {
			completeTask(newSortedItems, newErrors);
		} else {
			handleUpdateTask(
				'sort', 
				{sortedItems: newSortedItems, errors: newErrors}
			);
		}
	};

	/**
	 * Complete task
	 */
	const completeTask = (newSortedItems, newErrors) => {
		/* Return if not all items have been sorted */
		if (newSortedItems.some((item) => {return item.containerId === null;})) return;

		/* Save completed task */
		handleCompleteTask(
			'sort', 
			{sortedItems: newSortedItems, errors: newErrors}
		);
	};

	return (
		<div className={'Sort' + (taskData.layout ? ' ' + taskData.layout : '')}>
			{taskData.text && <div className="Sort-intro">
				{renderMarkdown(taskData.text)}
			</div>}
			<div className="Sort-content">
				<div className="Sort-categories">
					{taskData.categories && taskData.categories.map((categoryData) => {
						return (
							<div 
								key={categoryData.id} 
								className={'Sort-category ' + scenarioId + 
									(categoryData.type ? ' ' + categoryData.type : '')}
							>
								{(categoryData.type === 'title' && categoryData.title) &&
									<div className="Sort-categoryTitle">
										<span>{categoryData.title}</span>
									</div>
								}
								<SortDndContainer
									layout={taskData.layout}
									containerId={categoryData.id}
									handleFindItem={handleFindItem}
									handleMoveItem={handleMoveItem}
								>
									{sortedItems.filter((item) => {
										return item.containerId === categoryData.id;
									}).map((item) => {
										const itemData = taskData.items.find((itemData) => {
											return itemData.id === item.itemId;
										});
										if (!itemData) return null;

										let isDraggable = true;
										let classes = ['placed'];
										if (isCompleted) {
											isDraggable  = false;
											classes.push('completed');
										} else {
											const isPlacedCorrect = 
												(itemData.categoryIds.indexOf(item.containerId) >= 0);
											if (isPlacedCorrect) {
												classes.push('animateCorrect');
											} else {
												classes.push('animateWrong');
											}
											if (isPlacedCorrect) isDraggable = false;
										}
										const text = (itemData.type === 'text' && itemData.text
											? renderMarkdown(itemData.text)
											: null
										);
										return (
											<SortDndItem 
												key={itemData.id} 
												isDraggable={isDraggable} 
												classes={classes}
												type={itemData.type}
												layout={taskData.layout}
												itemId={itemData.id}
												scenarioId={scenarioId}
											>
												{text}
											</SortDndItem>
										);
									})}
								</SortDndContainer>
							</div>
						);
					})}
				</div>
				<div className="Sort-items">
					{sortedItems.map((item) => {
						if (item.containerId !== null) {
							return (
								<div key={item.itemId} className="Sort-itemGhost" />
							);
						}
						const itemData = taskData.items.find((itemData) => {
							return itemData.id === item.itemId;
						});
						if (!itemData) return null;
						const isDraggable = (!isCompleted);
						const text = (itemData.type === 'text' && itemData.text
							? renderMarkdown(itemData.text)
							: null
						);
						return (
							<SortDndItem 
								key={item.itemId} 
								isDraggable={isDraggable} 
								layout={taskData.layout}
								type={itemData.type}
								itemId={item.itemId}
								scenarioId={scenarioId}
							>
								{text}
							</SortDndItem>
						);
					})}
				</div>
				<SortDndPreview layout={taskData.layout} itemsData={taskData.items} />
			</div>
		</div>
	);
};

Sort.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleUpdateTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default Sort;
