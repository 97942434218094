/**
 * Sort an array of objects by a specific property (tiebreaker second and third properties optional)
 * @param {array} array 
 * @param {string} propertyName 
 * @param {string} order 
 * @param {string} secondPropertyName 
 * @param {string} secondOrder 
 * @param {string} thirdPropertyName 
 * @param {string} thirdOrder 
 * @returns 
 */
export function sortArrayByProperty(
	array, propertyName, order = 'ASC',
	secondPropertyName = null, secondOrder = 'ASC',
	thirdPropertyName = null, thirdOrder = 'ASC'
) {
	if (!array || array.length < 2) return array;
	let sortedArray = array.sort(function(a, b) {
		/* Sort by third property */
		if (
			a[propertyName] === b[propertyName] && secondPropertyName &&
			a[secondPropertyName] === b[secondPropertyName] && thirdPropertyName
		) {
			if (
				a.hasOwnProperty(thirdPropertyName) && a[thirdPropertyName] &&
				b.hasOwnProperty(thirdPropertyName) && b[thirdPropertyName] 
			) {
				if (thirdOrder === 'ASC') {
					if (a[thirdPropertyName] < b[thirdPropertyName]) { return -1; }
					if (a[thirdPropertyName] > b[thirdPropertyName]) { return 1; }
					return 0;
		
				} else if (thirdOrder === 'DESC') {
					if (a[thirdPropertyName] > b[thirdPropertyName]) { return -1; }
					if (a[thirdPropertyName] < b[thirdPropertyName]) { return 1; }
					return 0;
				}
				return 0;
			} 
			return 0;
		} 

		/* Sort by secondary property */
		if (a[propertyName] === b[propertyName] && secondPropertyName) {
			if (
				a.hasOwnProperty(secondPropertyName) && a[secondPropertyName] &&
				b.hasOwnProperty(secondPropertyName) && b[secondPropertyName] 
			) {
				if (secondOrder === 'ASC') {
					if (a[secondPropertyName] < b[secondPropertyName]) { return -1; }
					if (a[secondPropertyName] > b[secondPropertyName]) { return 1; }
					return 0;
		
				} else if (secondOrder === 'DESC') {
					if (a[secondPropertyName] > b[secondPropertyName]) { return -1; }
					if (a[secondPropertyName] < b[secondPropertyName]) { return 1; }
					return 0;
				}
				return 0;
			} 
			return 0;
		} 

		/* Sort by primary property */
		if (order === 'ASC') {
			if (a[propertyName] < b[propertyName]) { return -1; }
			if (a[propertyName] > b[propertyName]) { return 1; }
			return 0;
		} else if (order === 'DESC') {
			if (a[propertyName] > b[propertyName]) { return -1; }
			if (a[propertyName] < b[propertyName]) { return 1; }
			return 0;
		} 
		return 0;
	});
	return sortedArray;
};

/**
 * Sorts array by natural order. Meaning test2 would be placed before test10.
 * @param {array} array 
 * @param {string} property 
 * @returns 
 */
export function sortArrayNaturally(array, property) {
	const sortedArray = array.sort(function(a, b) {
		return a[property].localeCompare(b[property], undefined, {
			numeric: true
		});
	});
	return sortedArray;
};

/**
 * Sorts array of strings according to likeness og given value.
 * @param {array} list 
 * @param {string} value string value to check likeness against
 * @returns array with all list values containing value in order by likeness 
 */
export function sortArrayByLikeness(list, value, property) {
	const valueLower = value.toLowerCase();

	// Filtering off all values that do not contain the likeness value at all
	let resultingArray = list.filter((listValue) => {
		// Lower casing values to ignore differences in casing
		return listValue[property].toLowerCase().includes(valueLower);
	});

	resultingArray = resultingArray.sort((current, next) => {
		// Lower casing values to ignore differences in casing
		const nextLower = next[property].toLowerCase();
		const currentLower = current[property].toLowerCase();

		// Next is the search value, it should trumph all other values
		if (nextLower === valueLower) {
			return 1;
		}
		// Next starts with value while current doesn't
		if (nextLower.startsWith(valueLower) && !currentLower.startsWith(valueLower)) {
			return 1;
		}
		// Current starts with value while next doesn't
		if (!nextLower.startsWith(valueLower) && currentLower.startsWith(valueLower)) {
			return -1;
		}
		// Both starts with value, we check which one is closes to the full value
		if (nextLower.startsWith(valueLower) && currentLower.startsWith(valueLower)) {
			if (currentLower.length < nextLower.length) {
				return -1;
			} else if (currentLower.length > nextLower.length) {
				return 1;
			}
		}

		return 0;
	});

	return resultingArray;
}

/**
 * Randomly shuffle an array of integers
 * @param {array} array 
 * @returns 
 */
export function shuffleArray(array) {
	if (array.length < 2) {return array;}
	// https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
	return array.map((a) => { return [Math.random(), a];})
		.sort((a, b) => { return a[0] - b[0];})
		.map((a) => { return a[1];});
};