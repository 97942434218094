import React from 'react';
import PropTypes from 'prop-types';
import {calculateCompletionData, getGroupPoints} from 'helpers/game-helper';
import {scenariosData} from 'data/scenarios-data';
import AreaButton from 'components/ui/area-button/area-button';
import Info from 'components/ui/info/info';
import Logo from 'components/ui/logo/logo';
import InfoBar from 'components/ui/info-bar/info-bar';
import './area-hub.scss';

const AreaHub = ({ schoolData, group, infoTitle, infoText, routes, setPageId, scenarioId }) => {
	const groupName = group.name ? group.name : group.id;
	const groupPoints = getGroupPoints(group);
	
	return (
		<div className='AreaHub'>
			<div className='AreaHub-sideWrapper'>
				<div className='AreaHub-logo'><Logo scenarioId={scenarioId}/></div>
				{schoolData && <div className="AreaHub-schoolName"><span>{schoolData.name}</span></div>}
				<div className='AreaHub-infoWindow'>
					{(infoTitle && infoText) && 
						<Info 
							title={infoTitle} 
							text={infoText}
							scenarioId={scenarioId}
						/>
					}
				</div>
			</div>	
			
			<div className='AreaHub-middleWrapper'>
				<div className='AreaHub-header'>
					<InfoBar points={groupPoints} iconId='trophy' scenarioId={scenarioId}/>
					<div className='AreaHub-groupName'>
						<InfoBar text={groupName} iconId='person' scenarioId={scenarioId}/>
					</div>
				</div>
				<div className='AreaHub-areaWrapper'>
					<div className={'AreaHub-areaButtons ' + scenarioId}>
						{routes.map((route, index) => {
							const taskData = scenariosData[scenarioId].tasksData[route.id];
							const completionData = calculateCompletionData(taskData, group.assignments);
							return (
								<div key={index} className={'AreaHub-areaButton-' + index}>
									<AreaButton
										isComplete={completionData.completedPercentage === 100}
										areaButtonId={route.id}
										name={route.name} 
										onClick={() => {setPageId(route.pageId);}}
										scenarioId={scenarioId}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

AreaHub.propTypes = {
	schoolData: PropTypes.object,
	infoTitle: PropTypes.string.isRequired,
	infoText: PropTypes.string.isRequired,
	routes: PropTypes.array.isRequired,
	setPageId: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default AreaHub;