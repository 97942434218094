import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {checkIfGroupHasCompletedTask, checkIfTaskIsLocked} from 'helpers/game-helper';
import {getGroupPoints} from 'helpers/game-helper';
import AreaButton from 'components/ui/area-button/area-button';
import Button from 'components/ui/button/button';
import Info from 'components/ui/info/info';
import Logo from 'components/ui/logo/logo';
import InfoBar from 'components/ui/info-bar/info-bar';
import Assignment from 'components/assignment/assignment';
import './case.scss';
import PopupAssignment from 'components/ui/popup-assignment/popup-assignment';

const Case = (
	{ 
		group, 
		groups, 
		infoTitle, 
		routes, 
		id, 
		casesId, 
		setCasesId, 
		scenarioId, 
		updateGroup, 
		setPopupFileData, 
		setPageId
	}) => {
	/* Popup data for assignment popup */
	const [popupData, setPopupData] = useState(null);

	/* Selected task number */
	const [taskNumber, setTaskNumber] = useState(null);
	
	/* Group name and points */
	const groupName = group.name ? group.name : group.id;
	const groupPoints = getGroupPoints(group);
	
	/* Case title and text */
	const caseinfoTitle = routes.find((route) => {return route.id === casesId;})?.title;
	const caseinfoSubTitle = routes.find((route) => {return route.id === casesId;})?.subtitle;
	const caseinfoText = routes.find((route) => {return route.id === casesId;})?.text;
	const caseFiles = routes.find((route) => {return route.id === casesId;})?.files;

	/* All assignments in case */
	let caseTasksData = [];
	if (
		scenariosData.hasOwnProperty(scenarioId) && 
		scenariosData[scenarioId].hasOwnProperty('tasksData') &&
		scenariosData[scenarioId].tasksData.hasOwnProperty(id) &&
		scenariosData[scenarioId].tasksData[id]
	) {
		caseTasksData = scenariosData[scenarioId].tasksData[id].filter((c) => {return c.caseId === casesId;});
	}

	return (
		<div className='Case'>
			{popupData &&
				<PopupAssignment 
					title={popupData.title}
					text={popupData.text}
					closePopup={() => {setPopupData(null);}}
				/>
			}
			<div className='Case-header'>
				<div className='Case-logo'><Logo scenarioId={scenarioId}/></div>
				<InfoBar points={groupPoints} iconId='trophy' scenarioId={scenarioId}/>
				<div className='Case-groupName'>
					<InfoBar text={groupName} iconId='person' scenarioId={scenarioId}/>
				</div>	
			</div>
			<div className='Case-contentWrapper'> 
				<div className='Case-sideWrapper'>
					<div className='Case-areaButton'>
						<AreaButton
							areaButtonId={id}
							name={infoTitle} 
							onClick={() => {setCasesId(null);}}
							scenarioId={scenarioId}
						/>
					</div>
					<div className='Case-assignments'>
						<div className='Case-title'>
							<span>{caseinfoTitle + (caseinfoSubTitle ? ':' : '')}</span>
							{caseinfoSubTitle && <span>{caseinfoSubTitle}</span>}
						</div>
						{taskNumber && 
							<div className={'Case-infoBtn ' + scenarioId} onClick={() => {setTaskNumber(null);}}/>
						}
						
						<div className='Case-cases'>
							{caseTasksData.map((task, index) => {
								const assignmentIsCompleted = checkIfGroupHasCompletedTask(task.taskId, group);
								const assignmentIsCurrent = task.taskNumber === taskNumber;
								const assignmentIsLocked = checkIfTaskIsLocked(task, group);

								let caseBtnClasses = ['assignment', scenarioId];
								if (assignmentIsCompleted) caseBtnClasses.push('completed');
								else if (assignmentIsCurrent) caseBtnClasses.push('current');
								else if (assignmentIsLocked) caseBtnClasses.push('locked');
								return (
									<div key={index} className='Case-casesButton'>
										{assignmentIsLocked && 
											<div className='Case-lockLine'/>
										}
										<div className='Case-button'>
											<Button
												isDisabled={assignmentIsLocked}
												classes={caseBtnClasses} 
												text={task.title}
												onClick={() => {setTaskNumber(task.taskNumber);}}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="Case-backBtn">
						<Button 
							text={gameUiTexts.backBtn}
							classes={['backBtnGame', scenarioId, 'responsive']}
							onClick={() => {setCasesId(null);}}
						/>
					</div>
					
				</div>
				<div className='Case-middleWrapper'>
					{taskNumber ?
						<div className='Case-assignmentWrapper'>
							<Assignment 
								group={group} 
								groups={groups}
								scenarioId={scenarioId} 
								areaId={id} 
								caseId={casesId} 
								taskNumber={taskNumber} 
								updateGroup={updateGroup}
								setPopupFileData={setPopupFileData}
								setPopupData={setPopupData}
								setTaskNumber={setTaskNumber}
								setPageId={setPageId}
								setCasesId={setCasesId}
							/>
						</div>
						:
						<div className='Case-infoWindow'>
							<Info 
								title={caseinfoTitle + ':'}
								subtitle={caseinfoSubTitle}
								text={caseinfoText}
								files={caseFiles}
								setPopupFileData={setPopupFileData}
								scenarioId={scenarioId}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

Case.propTypes = {
	infoTitle: PropTypes.string.isRequired,
	routes: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
	casesId: PropTypes.number.isRequired,
	setCasesId: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	scenarioId: PropTypes.string.isRequired,
	updateGroup: PropTypes.func.isRequired,
	setPopupFileData: PropTypes.func.isRequired,
	setPageId: PropTypes.func.isRequired,
};

export default Case;