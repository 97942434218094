import React from 'react';
import './logos.scss';

const Logos = () => {
	return (
		<div className='Logos'>
			<div className='Logos-karrierindsatsen'/>

			<div className='Logos-cgl'/>
		</div>
	);
};

export default Logos;