/* Stars */
const starsPerPercentPoints = {
	pointSteps: [0, 20, 60, 90],
	filledStars: [0, 1, 2, 3]
};

/* Points */
const defaultMaxPoints = 100;
const defaultMinPoints = 20;
const errorPenalty = 20;

const inputAnswerMaxAttempts = 3;
const inputAnswerMinPoints = 0;


export {
	starsPerPercentPoints,
	defaultMaxPoints,
	defaultMinPoints,
	errorPenalty,
	inputAnswerMaxAttempts,
	inputAnswerMinPoints
};