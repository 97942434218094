import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './sort-dnd-container.scss';

const SortDndContainer = ({layout, containerId, handleFindItem, handleMoveItem, children}) => {
	/* Drop functionality */
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (handleFindItem && handleMoveItem) {
				const fromContainerId = handleFindItem(item.id);
				
				/* Ignore hovering over itself */
				if (containerId === fromContainerId) return;

				/* Move card */
				handleMoveItem(item.id, containerId);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		}
	});

	return (
		<div 
			id={containerId} 
			ref={drop}
			className={'SortDndContainer ' + layout + ' ' + containerId + (isOver && canDrop ? ' hover' : '')}
		>
			{children}
		</div>
	);
};

SortDndContainer.propTypes = {
	layout: PropTypes.string.isRequired,
	containerId: PropTypes.string.isRequired,
	handleFindItem: PropTypes.func.isRequired,
	handleMoveItem: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
};

export default SortDndContainer;
