function checkDataForUndefined(data) {
	for (const key in data) {
		// If key is object check object for undefined 
		if (typeof data[key] === 'object' && data[key] !== null) {
			const hasUndefined = checkDataForUndefined(data[key]);

			if (hasUndefined) {
				return true;
			}
		}

		if (data[key] === undefined) {
			return true;
		}
	}

	return false;
}

export {
	checkDataForUndefined
};