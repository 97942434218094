import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import { errorUiTexts, schoolUiTexts } from 'data/ui-texts';
import './popup-create-game.scss';

const PopupCreateGame = ({createGame, closePopup}) => {
	const [name, setName] = useState('');
	const [studentCount, setStudentCount] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	/**
	 * Handles form input
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		if (event.target.name === 'name') {
			setName(event.target.value);
		} else {
			setStudentCount(event.target.value);
		}
	};

	/**
	 * Handles game creation, closes popup
	 */
	const handleCreateGame = () => {
		setIsLoading(true);

		createGame(name, studentCount).then((response) => {
			if (response.status === 'success') {
				setIsLoading(false);
				closePopup();
			} else {
				setErrorMessage(errorUiTexts.createGameError);
				setIsLoading(false);
			}
		});
	};

	return (
		<div className='PopupCreateGame' onClick={() => {closePopup();}}>
			<div className='PopupCreateGame-window' onClick={(e) => {e.stopPropagation();}}>
				<div className='PopupCreateGame-title'>
					<span>{schoolUiTexts.landingPage.createGame}</span>
				</div>
				<form className='PopupCreateGame-form' onSubmit={handleCreateGame}>
					<div className={'PopupCreateGame-input'}>
						<div className='PopupCreateGame-inputTitle'>
							<span>{'Spilnavn:'}</span>
						</div>
						<input
							name='name'
							type='text'
							placeholder={'Spil'}
							autoComplete='section-inputAnswer name'
							value={name}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
					<div className={'PopupCreateGame-input'}>
						<div className='PopupCreateGame-inputTitle'>
							<span>{'Antal elever:'}</span>
						</div>
						<input
							name='studentCount'
							type='number'
							placeholder={'Elever'}
							autoComplete='section-inputAnswer studentCount'
							value={studentCount}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
					<div className="PopupCreateGame-submitButton">
						<Button
							isDisabled={isLoading}
							text={'Opret'}
							classes={['confirmTask']}
							onClick={() => {handleCreateGame();}}
						/>
					</div>
				</form>

				{errorMessage &&
					<div className='PopupCreateGame-errorMessage'>
						<span>{errorMessage}</span>
					</div>
				}
			</div>
		</div>
	);
};

PopupCreateGame.propTypes = {
	createGame: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default PopupCreateGame;