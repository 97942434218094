import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './popup-delete-school.scss';

const PopupDeleteSchool = ({schoolData, deleteSchool, closePopup}) => {
	const popupText = 
		JSON.parse(JSON.stringify(adminUiTexts.deleteSchoolPopup.text)).replace('%school%', schoolData.name);
	
	return (
		<div onClick={() => {closePopup();}} className='PopupDeleteSchool'>
			<div onClick={(e) => {e.stopPropagation();}} className='PopupDeleteSchool-dialogue'>
				<div className='PopupDeleteSchool-title'>
					<span>{adminUiTexts.deleteSchoolPopup.title}</span>
				</div>
				<div className='PopupDeleteSchool-text'>
					{renderMarkdown(popupText)}
				</div>
				<div className='PopupDeleteSchool-buttons'>
					<div className='PopupDeleteSchool-confirmBtn'>
						<Button
							text={adminUiTexts.deleteSchoolPopup.confirmDelete}
							classes={['blueRounded']}
							onClick={() => {
								deleteSchool(schoolData.id);
								closePopup();
							}}
						/>
					</div>
					<div className='PopupDeleteSchool-cancelBtn'>
						<Button
							text={adminUiTexts.deleteSchoolPopup.cancelDelete}
							classes={['blueRounded']}
							onClick={() => {
								closePopup();
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupDeleteSchool.propTypes = {
	schoolData: PropTypes.object.isRequired,
	deleteSchool: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default PopupDeleteSchool;