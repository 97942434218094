import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './task-intro.scss';

const TaskIntro = (
	{
		showNumberOfAnswersToSelect, 
		numberOfCorrectAnswers, 
		text, 
		image, 
		files, 
		setPopupFileData, 
		scenarioId
	}) => {
	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className="TaskIntro-text">
				{text && renderMarkdown(text)}
				{files && <div className="TaskIntro-files">
					{files.map((file, index) => {
						return (
							<Button 
								key={index}
								text={file.btnText}
								classes={['login', scenarioId]}
								onClick={() => {setPopupFileData({type: file.type, fileId: file.id});}}
							/>
						);
					})}
				</div>}
				{showRequiredNumberOfAnswers && 
					<div className="TaskIntro-requiredAnswers">{gameUiTexts.choose} {numberOfCorrectAnswers}:</div>}
			</div>
			
			{image && <div className="TaskIntro-image" />}
		</div>
	);
};

TaskIntro.propTypes = {
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string,
	files: PropTypes.array,
	setPopupFileData: PropTypes.func,
	scenarioId: PropTypes.string.isRequired,
};

export default TaskIntro;
