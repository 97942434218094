import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getSchoolStats} from 'helpers/stats-helper';
import {loginUiTexts, adminUiTexts, schoolUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import PopupCreateSchool from 'components/admin/popup-create-school/popup-create-school';
import PopupEditSchool from './popup-edit-school/popup-edit-school';
import PopupDeleteSchool from './popup-delete-school/popup-delete-school';
import './admin.scss';

const Admin = ({handleLogout}) => {
	/* Schools & games */
	const [schools, setSchools] = useState([]);
	const [games, setGames] = useState([]);

	/* Add school popup */
	const [showAddSchoolPopup, setShowAddSchoolPopup] = useState(false);

	/* Edit school data */
	const [editSchoolData, setEditSchoolData] = useState(null);

	/* Delete school popup */
	const [deleteSchoolData, setDeleteSchoolData] = useState(null);
	

	/**
	 * Create new school
	 * @param {string} name 
	 * @param {string} email 
	 * @param {string} password 
	 * @param {array} scenarioIds
	 * @returns 
	 */
	const createSchool = (name, email, password, scenarioIds) => {
		return new Promise((resolve) => {
			apiHelper('admin/create-school', {
				name, email, password, scenarioIds
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	const editSchool = (schoolId, name, email, password, scenarioIds) => {
		return new Promise((resolve) => {
			apiHelper('admin/edit-school', {
				schoolId, name, email, password, scenarioIds
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Delete school
	 * @param {string} schoolId
	 * @returns 
	 */
	const deleteSchool = (schoolId) => {
		return new Promise((resolve) => {
			apiHelper('admin/delete-school', {schoolId}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		const db = firebase.firestore();
		
		/* Subscribe to schools */
		const unsubscribeSchools = db.collection(appConfig.schoolsDbName).onSnapshot(
			(querySnapshot) => {
				const dbSchools = [];
				querySnapshot.forEach((doc) => {dbSchools.push({id: doc.id, ...doc.data()});});
				setSchools(dbSchools);
			},
			(error) => {
				console.error('could not get schools: ', error);
			}
		);

		/* Subscribe to games */
		const unsubscribeGames = db.collection(appConfig.gamesDbName).onSnapshot(
			(querySnapshot) => {
				const dbGames = [];
				querySnapshot.forEach((doc) => {dbGames.push({id: doc.id, ...doc.data()});});
				setGames(dbGames);
			},
			(error) => {
				console.error('could not get games: ', error);
			}
		);


		return () => {
			/* Cancel subscriptions */
			unsubscribeSchools();	
			unsubscribeGames();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Admin">
			<div className="Admin-title">
				<span>{schoolUiTexts.admin}</span>
			</div>
			<div className='Admin-schools'>
				{/* Create new school btn */}
				<div className='Admin-createSchoolBtn'>
					<Button
						classes={['login']}
						text={adminUiTexts.createSchool}
						onClick={() => {setShowAddSchoolPopup(true);}}
					/>
				</div>
				{/* List of schools */}
				<div className='Admin-row header'>
					<div className='Admin-item name'>
						<span>{adminUiTexts.schoolList.school}</span>
					</div>
					<div className='Admin-item email'>
						<span>{adminUiTexts.schoolList.email}</span>
					</div>
					<div className='Admin-item password'>
						<span>{adminUiTexts.schoolList.password}</span>
					</div>
					<div className='Admin-item students'>
						<span>{adminUiTexts.schoolList.students}</span>
					</div>
					<div className='Admin-item plays'>
						<span>{adminUiTexts.schoolList.playthroughs}</span>
					</div>
					<div className='Admin-item edit'>
						<span>{adminUiTexts.schoolList.edit}</span>
					</div>
					<div className='Admin-item delete'>
						<span>{adminUiTexts.schoolList.delete}</span>
					</div>
				</div>

				{schools && <div className="Admin-rows">
					{schools.map((school, index) => {
						const schoolGames = games.filter((g) => {return g.schoolId === school.id;});
						const {playthroughCount, studentCount} = getSchoolStats(school, schoolGames);
						return (
							<div key={index} className='Admin-row body'>
								<div className='Admin-item name'>
									<span>{school.name}</span>
								</div>
								<div className='Admin-item email'>
									<span>{school.email}</span>
								</div>
								<div className='Admin-item password'>
									<span>{school.password}</span>
								</div>
								<div className='Admin-item students'>
									<span>{studentCount}</span>
								</div>
								<div className='Admin-item plays'>
									<span>{playthroughCount}</span>
								</div>
								<div className='Admin-item edit' onClick={() => {setEditSchoolData(school);}}/>
								<div className='Admin-item delete' onClick={() => {setDeleteSchoolData(school);}}/>
							</div>
						);
					})} 
				</div>}
			</div>

			{/* Create school popup */}
			{showAddSchoolPopup && <PopupCreateSchool 
				schools={schools}
				createSchool={createSchool}
				closePopup={() => {setShowAddSchoolPopup(false);}} 
			/>}

			{/* Edit school popup */}
			{editSchoolData && <PopupEditSchool 
				school={editSchoolData}
				schools={schools}
				editSchool={editSchool}
				closePopup={() => {setEditSchoolData(false);}} 
			/>}

			{/* Delete school popup */}
			{deleteSchoolData && <PopupDeleteSchool 
				schoolData={deleteSchoolData} 
				deleteSchool={deleteSchool} 
				closePopup={() => {setDeleteSchoolData(null);}} 
			/>}

			{/* Logout button */}
			<div className="Admin-logoutBtn">
				<Button 
					classes={['login', 'logout']} 
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

Admin.propTypes = {
	handleLogout: PropTypes.func.isRequired,
};

export default Admin;
