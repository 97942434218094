import React, {useEffect, useState} from 'react';
import './info-bar.scss';
import PropTypes from 'prop-types';

const InfoBar = ({ text, iconId, points, scenarioId }) => {

	const [currentPoints, setCurrentPoints] = useState(points);
	const [currentText, setCurrentText] = useState(text);

	let timeout = null;
	if (points && currentPoints < points) {
		let newCurrentPoints = currentPoints + 1;
		// End interval once we reach points
		timeout = setTimeout(() => {
			setCurrentPoints(newCurrentPoints);
		}, 10);
	}

	// Set current points to points on mount
	useEffect(() => {
		setCurrentPoints(points);
		setCurrentText(text);
		// Clear intervals if any on unmount
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='InfoBar'>
			<div className={'InfoBar-icon ' + scenarioId + ' ' + iconId}/>
			<div className={'InfoBar-text ' + scenarioId + (currentPoints != null ? ' isPoints' : '')}>
				<span>{currentText ? currentText : currentPoints}</span>
			</div>
		</div>
	);
};

InfoBar.propTypes = {
	text: PropTypes.string,
	points:  PropTypes.number,
	iconId: PropTypes.string.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default InfoBar;