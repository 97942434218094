import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './group-name.scss';

const GroupName = ({layout, groupId, currentGroupName, updateGroup}) => {
	/* Editing / saving status */
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	/* New group name */
	const [newGroupName, setNewGroupName] = useState(currentGroupName);

	/**
	 * Start editing
	 */
	const startEditing = () => {
		setNewGroupName(currentGroupName);
		setIsEditing(true);
	};

	/**
	 * Update group name
	 * @param {object} event 
	 * @returns 
	 */
	const updateGroupName = (event) => {
		if (isSaving) return;
		setNewGroupName(event.target.value);
	};

	/**
	 * Save 
	 */
	const handleSave = () => {
		if (isSaving) return;

		if (newGroupName.length > 0) {
			updateGroup(groupId, {name: newGroupName}).then(() => {
				setIsEditing(false);
				setIsSaving(false);
			}).catch((error) => {
				console.error(error);
				setIsEditing(false);
				setIsSaving(false);
			});
		}
	};

	return (
		<div className={'GroupName ' + layout + (isEditing ? ' editing' : '') + (isSaving ? ' saving' : '')}>
			{(!isEditing && !isSaving) && <span>{currentGroupName}</span>}
			{(isEditing || isSaving) && <input value={newGroupName} onChange={(e) => {updateGroupName(e);}}/>}
			<div className="GroupName-editBtn" onClick={() => {startEditing();}} />
			<div className="GroupName-saveBtn" onClick={() => {handleSave(); }}/>
		</div>
	);
};

GroupName.propTypes = {
	layout: PropTypes.string.isRequired,
	groupId: PropTypes.string.isRequired,
	currentGroupName: PropTypes.string.isRequired,
	updateGroup: PropTypes.func.isRequired
	
};

export default GroupName;