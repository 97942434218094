import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({scenarioId, page}) => {
	return (
		<div className={'Logo' + (scenarioId ? ' ' + scenarioId : '') + (page ? ' ' + page : '')}/>
	);
};


Logo.propTypes = {
	scenarioId: PropTypes.string,
	page: PropTypes.string,
};

export default Logo;
