import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts, schoolUiTexts} from 'data/ui-texts';
import LoginSchoolController from './login-school-controller';
import LoginGroupController from './login-group-controller';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './login.scss';

const Login = ({scenarioId, schools}) => {
	return (
		<div className={'Login ' + scenarioId}>
			<div className="Login-welcome"><span>{loginUiTexts.welcome}</span></div>
			<div className='Login-logoWrapper'><Logo scenarioId={scenarioId}/></div>
			
			<div className="Login-wrap">
				<div className="Login-school">
					<LoginSchoolController scenarioId={scenarioId} schools={schools} />
				</div>
				<div className="Login-group">
					<LoginGroupController scenarioId={scenarioId} />
				</div>
			</div>

			<div className="Login-backBtn">
				<Button 
					classes={['backBtnGame', scenarioId]} 
					text={schoolUiTexts.back}
					type="a"
					href="/"
				/>
			</div>
		</div>
	);
};
Login.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	schools: PropTypes.array.isRequired
};
export default Login;