import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {schoolUiTexts, gameUiTexts} from 'data/ui-texts';
import { soundsData } from 'data/sound-data';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import Leaderboard from 'components/ui/leaderboard/leaderboard';
import './game-done.scss';

const GameDone = ({gameGroups, handleGoToPage, updateBackground, scenarioId, handlePlayMusic, updateGroup}) => {

	/* Toggle leaderboard */
	const [showLeaderboard, setShowLeaderboard] = useState(false);

	// Navigates back to landing page and stops the music
	const handleGoBack = () => {
		handlePlayMusic(false, null);
		handleGoToPage('landing');
	};

	useEffect(() => {
		// Set background image id
		updateBackground('school', scenarioId);
		handlePlayMusic(false, soundsData.login);
		
		return () => {
			handlePlayMusic(false, null);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	

	return (
		<div className="GameDone">
			{/* Logo */}
			<div className="GameDone-logo" onClick={() => {handleGoToPage('landing');}} >
				<Logo scenarioId={scenarioId} />
			</div>


			{/* Content */}
			<div className="GameDone-content">
				<div className="GameDone-title">
					<span>{gameUiTexts.gameOver}</span>
				</div>
				<div className="GameDone-buttons">
					<div className="GameDone-leaderboardBtn">
						<Button 
							classes={['blueLight', scenarioId, 'trophy']}
							text={schoolUiTexts.leaderboard}
							onClick={() => {setShowLeaderboard(true);}}
						/>
					</div>
					<div className="GameDone-backBtn">
						<Button 
							classes={['blueLight', scenarioId, 'backBtn']}
							text={schoolUiTexts.back}
							onClick={() => {handleGoBack();}}
						/>
					</div>
				</div>
			</div>
		
			{/* Leaderboard  */}
			{showLeaderboard && <div className="GameDone-leaderBoard">
				<div className='GameDone-leaderBoardTitle'><span>{schoolUiTexts.gameDonePage.title}</span></div>
				<Leaderboard 
					scenarioId={scenarioId} 
					status="done" 
					gameGroups={gameGroups} 
					updateGroup={updateGroup}
				/>
			</div>}

			{/* Back to landing page btn */}
			<div className="GameDone-backBtn2">
				<Button 
					classes={['blueLight', scenarioId, 'backBtn']}
					text={schoolUiTexts.back}
					onClick={() => {handleGoBack();}}
				/>
			</div>
		</div>
	);
};

GameDone.propTypes = {
	gameGroups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired
};

export default GameDone;
