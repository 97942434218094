import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './order-dnd-container.scss';

const OrderDndContainer = ({isDroppable, layout, location, positionNumber, handleMoveItem}) => {
	/* Drop functionality */
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (!isDroppable) return;

			if (handleMoveItem) {				
				/* Ignore hovering over itself */
				if (
					item.location === location &&  
					item.positionNumber === positionNumber
				) return;

				/* Move card */
				handleMoveItem(item.id, location, positionNumber);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: isDroppable
			};
		}
	});

	return (
		<div 
			ref={drop}
			className={'OrderDndContainer' + ((isOver && canDrop) ? ' hover' : '')
				+ (layout ? ' ' + layout : '')}
		/>
	);
};

OrderDndContainer.propTypes = {
	isDroppable: PropTypes.bool.isRequired,
	layout: PropTypes.string,
	location: PropTypes.string.isRequired,
	positionNumber: PropTypes.number.isRequired,
	handleMoveItem: PropTypes.func.isRequired,
};

export default OrderDndContainer;
