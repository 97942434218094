import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {loginUiTexts, errorUiTexts, adminUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Admin from './admin';
import './admin-login.scss';

const AdminLogin = ({updateBackground}) => {
	/* Auth state ref */
	const unsubscribeOnAuthStateChanged = useRef(null);

	/* Auth data */
	const [authData, setAuthData] = useState(null);

	/* Login page */
	const [loginPage, setLoginPage] = useState('login'); // login, reset

	/* Input: email & password */
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	
	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Feedback */
	const [feedback, setFeedback] = useState(null);

	/**
	 * Check if user is logged in
	 */
	const checkIfLoggedIn = () => {
		/* Unsubscribe previous onAuthStateChanged */
		if (unsubscribeOnAuthStateChanged.current !== null) {
			unsubscribeOnAuthStateChanged.current();
		}
		/* Subscribe to onAuthStateChanged */
		unsubscribeOnAuthStateChanged.current = firebase.auth().onAuthStateChanged((user)=>{
			if (user && user.email) {
				setAuthData({userId: user.uid, email: user.email});
				setIsLoading(false);
			} else {
				setAuthData(null);
				setIsLoading(false);
			}
		});	
	};

	/**
	 * Update input field (password)
	 * @param {obj} event 
	 */
	const handleInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setFeedback(null);
		if (name === 'email') {
			setEmail(value);
		}
		if (name === 'password') {
			setPassword(value);
		}
	};

	/**
	 * Handle login
	 * @param {object} event 
	 * @returns 
	 */
	const handleLogin = (event) => {
		if (event) event.preventDefault();
		if (isLoading) return;

		setIsLoading(true);
		setFeedback(null);

		let newFeedback = null;
		if (!email || !password) {
			newFeedback = errorUiTexts.emptyFields;
		}

		if (newFeedback) {
			setFeedback(newFeedback);
			setIsLoading(false);
		} else {
			firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
				if (error.code === 'auth/user-not-found') newFeedback = errorUiTexts.userNotFound;
				if (error.code === 'auth/wrong-password') newFeedback = errorUiTexts.invalidPassword;
				if (error.code === 'auth/invalid-login-credentials') newFeedback = errorUiTexts.invalidPassword;
				if (!newFeedback) {
					console.error(error);
					newFeedback = errorUiTexts.unknownError;
				}
				setFeedback(newFeedback);
				setIsLoading(false);
			});
		}
	};

	/**
	 * Reset password
	 * @param {object} event 
	 * @returns 
	 */
	const handleResetPassword = (event) => {
		if (event) event.preventDefault();
		if (isLoading) return;

		setIsLoading(true);
		setFeedback(null);
		let newFeedback = null;
		if (!email) {
			newFeedback = errorUiTexts.emptyFields;
		}

		if (newFeedback) {
			setFeedback(newFeedback);
			setIsLoading(false);
		} else {
			const auth = firebase.auth();
			auth.sendPasswordResetEmail(email).then(()=>{
				setIsLoading(false);
				setFeedback(loginUiTexts.passwordIsReset);
			}).catch((error) =>{
				console.error(error);
				setIsLoading(false);
				setFeedback(errorUiTexts.unknownError);
			});
		}
	};

	/**
	 * Logout
	 */
	const handleLogout = () => {
		firebase.auth().signOut();
		setAuthData(null);
	};

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Check if logged in */
		checkIfLoggedIn();

		updateBackground('school', 'game2');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	if (!authData) {
		if (loginPage === 'login') {
			/* Login */
			return (
				<div className="AdminLogin">
					<div className="AdminLogin-wrap">
						<div className="AdminLogin-title">
							<span>{loginUiTexts.login} - {adminUiTexts.admin}</span>
						</div>
						<div className="AdminLogin-body">
							<form className="AdminLogin-form" onSubmit={(e) => {handleLogin(e);}}>
								<input
									name="email"
									className='AdminLogin-input'
									type="text"
									placeholder={loginUiTexts.email}
									autoComplete="section-adminLogin email"
									value={email ? email : ''} 
									onChange={(event)=>{handleInput(event);}}
								/>						
								<input
									name="password"
									className='AdminLogin-input'
									type="password"
									placeholder={loginUiTexts.password}
									autoComplete="section-adminLogin password"
									value={password ? password : ''} 
									onChange={(event)=>{handleInput(event);}}
								/>
								<div 
									className="AdminLogin-pageBtn" 
									onClick={() => {setLoginPage('reset'); setFeedback(null);}}
								>
									<span>{loginUiTexts.resetPassword}</span>
								</div>
								<div className="AdminLogin-loginBtn">
									<Button 
										isLoading={isLoading}
										classes={['login']}
										text={loginUiTexts.login}
										onClick={() => {handleLogin();}}
										type='submit'
									/>
								</div>
							</form>
							<div className="AdminLogin-errorMessage">{feedback}</div> 
						</div>
					</div>
				</div>
			);
		}
		/* Reset password */
		return (
			<div className="AdminLogin">
				<div className="AdminLogin-wrap">
					<div className="AdminLogin-title">
						<span>{loginUiTexts.resetPassword}</span>
					</div>
					<div className="AdminLogin-body">
						<form className="AdminLogin-form" onSubmit={(e) => {handleResetPassword(e);}}>
							<input
								name="email"
								className='AdminLogin-input'
								type="text"
								placeholder={loginUiTexts.email}
								autoComplete="section-adminLogin email"
								value={email ? email : ''} 
								onChange={(event)=>{handleInput(event);}}
							/>						
							<div 
								className="AdminLogin-pageBtn" 
								onClick={() => {setLoginPage('login'); setFeedback(null);}}
							>
								<span>{loginUiTexts.backToLogin}</span>
							</div>
							<div className="AdminLogin-loginBtn">
								<Button 
									isLoading={isLoading}
									classes={['login']}
									text={loginUiTexts.reset}
									onClick={() => {handleResetPassword();}}
									type='submit'
								/>
							</div>
						</form>
						<div className="AdminLogin-errorMessage">{feedback}</div> 
					</div>
				</div>
			</div>
		);
	}

	return (
		<Admin authData={authData} handleLogout={handleLogout} />
	);	
};

AdminLogin.propTypes = {
	updateBackground: PropTypes.func.isRequired
};

export default AdminLogin;