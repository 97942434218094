import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import firebaseInit from 'firebase-init';
import {scenariosData} from 'data/scenarios-data';
import AdminLogin from './admin/admin-login';
import SelectGame from './select-game/select-game';
import LoginController from './login/login-controller';
import Background from './ui/background/background';
import Audio from './ui/audio/audio';
import Button from './ui/button/button';
import './app.scss';

const App = () => {
	const [currentBackground, setCurrentBackground] = useState({
		backgroundId: null,
		scenarioId: null,
	});

	const [audioSrc, setAudioSrc] = useState(null);
	const [playAudio, setPlayAudio] = useState(false);
	/**
	 * Component mounted / will unmount
	 */
	 useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Component will ummount */
		// return () => {};
	});

	const updateBackground = (newBackground, scenarioId) => {
		setCurrentBackground({backgroundId: newBackground, scenarioId});
	};

	// Sets source and activates/stops music playing
	const handlePlayMusic = (shouldPlay, source) => {
		setAudioSrc(source);
		setPlayAudio(shouldPlay);
	};
		
	return (
		<div className="App">
			{audioSrc &&
				<div className='App-audioWrapper'>
					<Audio audioSrc={audioSrc} isPlaying={playAudio}/>
					<Button 
						text={''}
						classes={['audio', (playAudio ? ' playing' : ' notPlaying')]}
						onClick={() => {setPlayAudio(!playAudio);}}
					/>
				</div>
			}
			{currentBackground.backgroundId &&
				<Background background={currentBackground}/>
			}
			<Router>
				<Routes>
					<Route 
						path={'/admin'}
						element={<AdminLogin 
							updateBackground={updateBackground} 
						/>}
					/>
					<Route 
						path={'/' + scenariosData.game1.url}
						element={<LoginController 
							scenarioId="game1" 
							updateBackground={updateBackground} 
							handlePlayMusic={handlePlayMusic}
						/>}
					/>
					<Route 
						path={'/' + scenariosData.game2.url}
						element={<LoginController 
							scenarioId="game2" 
							updateBackground={updateBackground} 
							handlePlayMusic={handlePlayMusic}
						/>}
					/>
					<Route 
						path="*" 
						element={<SelectGame />}					
					/>
				</Routes>
			</Router>
		</div>
	);
};

export default App;
