import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import './countdown.scss';
import { gameUiTexts } from 'data/ui-texts';

const Countdown = ({startTime = null, layout, minutes}) => {
	const [isTimeOut, setIsTimeOut] = useState(false);

	// Showing minutes split in tens and without tens
	const [minutesTens, setMinutesTens] = useState(Math.floor(minutes / 10));
	const [minutesOnes, setMinutesOnes] = useState(minutes % 10);
	
	// Showing seconds split in tens and without tens
	const [secondsTens, setSecondsTens] = useState(0);
	const [secondsOnes, setSecondsOnes] = useState(0);

	/**
	 * Every second we update our current time using the initially calculated end time
	 * If we are past our end time, then we stop our interval.
	*/
	useEffect(() => {
		// Getting end time in milliseconds
		
		let endTime = 0;
		if (startTime) {
			endTime = new Date(startTime + minutes * 60000);
		} else {
			endTime = new Date(new Date().getTime() + minutes * 60000);
		}
	
		const interval = setInterval(() => {			
			// Finding current time difference
			const currentTime = new Date();

			if (endTime > currentTime) {
				const timeDifference = endTime - currentTime;
				const totalSecondsLeft = timeDifference / 1000;
				const secondsInterval = totalSecondsLeft % 60;
				const minutesLeft = totalSecondsLeft / 60;

				// Getting seconds split in tens and without tens
				const secTens = Math.floor(secondsInterval / 10);
				const secOnes = Math.floor(secondsInterval - (secTens * 10));

				// Getting minutes split in tens and without tens
				const minTens = Math.floor(minutesLeft / 10);
				const minOnes = Math.floor(minutesLeft - (minTens * 10));
	
				setMinutesTens(minTens);
				setMinutesOnes(minOnes);
				setSecondsTens(secTens);
				setSecondsOnes(secOnes);
			} else {
				setIsTimeOut(true);
				setMinutesTens(0);
				setMinutesOnes(0);
				setSecondsTens(0);
				setSecondsOnes(0);
				clearInterval(interval);
			}

		}, 500);
		return () => {clearInterval(interval);};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		<div className={'Countdown ' + layout}>
			{(isTimeOut && layout !== 'small') && 
				<div className='Countdown-timeout'>
					<span>{gameUiTexts.timesOut}</span>
				</div>
			}
			{/* Minutes */}
			<div className={'Countdown-wrapper ' + layout}>
				<div className={'Countdown-box' + (isTimeOut ? ' timesOut' : '')}>
					<span>{minutesTens}</span>
				</div>
				<div className={'Countdown-box' + (isTimeOut ? ' timesOut' : '')}>
					<span>{minutesOnes}</span>
				</div>
				<div className='Countdown-colon'/>
				{/* Seconds */}
				<div className={'Countdown-box' + (isTimeOut ? ' timesOut' : '')}>
					<span>{secondsTens}</span>
				</div>
				<div className={'Countdown-box' + (isTimeOut ? ' timesOut' : '')}>
					<span>{secondsOnes}</span>
				</div>
			</div>
		</div>
	);
};

Countdown.propTypes = {
	startTime: PropTypes.number,
	layout: PropTypes.string.isRequired,
	minutes: PropTypes.number.isRequired,
};

export default Countdown;

 