import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import apiHelper from 'helpers/api-helper';
import {errorUiTexts} from 'data/ui-texts';
import LoginSchool from './login-school';

class LoginSchoolController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			schoolId: null,
			password: null,
			feedback: null,
		};
	}

	/**
	 * Update input field (password)
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		const value = event.target.value;
		const name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};

	/**
	 * Select school
	 * @param {string} schoolId 
	 */
	selectSchool = (schoolId) => {
		this.setState({schoolId});
	};
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();
		if (this.state.isLoading) return;

		this.setState({isLoading: true}, () => {
			let schoolId = this.state.schoolId;
			let password = this.state.password;
			let feedback = null;

			const schoolData = this.props.schools.find((s) => {return s.id === schoolId;});

			if (!schoolData) {
				feedback = errorUiTexts.invalidSchool;
			} else if (!password) {
				feedback = errorUiTexts.emptyFields;
			}
			if (!feedback) {
				apiHelper('school/login', {schoolId, password}).then((response)=>{
					if (response.status === 'success' && response.token) {
						firebase.auth().signInWithCustomToken(response.token).catch((error) => {
							console.error('sign in error', error);
							this.setState({
								feedback: errorUiTexts.unknownError,
								isLoading: false,
							});
						});
					} else {
						let feedback = errorUiTexts.unknownError;
						if (response.error === 'invalid-password') {
							feedback = errorUiTexts.invalidPassword;
						}
						this.setState({isLoading: false, feedback});
					}
				}).catch((error) => {
					console.error(error);
					this.setState({
						isLoading: false,
						feedback: errorUiTexts.unknownError
					});
				});
			};
		});
	};

	render = () => {
		return (
			<LoginSchool
				isLoading={this.state.isLoading}
				scenarioId={this.props.scenarioId}
				schoolId={this.state.schoolId}
				password={this.state.password}
				feedback={this.state.feedback}
				schools={this.props.schools}
				selectSchool={this.selectSchool}
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
			/>
		);
	};
}

LoginSchoolController.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	schools: PropTypes.array.isRequired
};

export default LoginSchoolController;