import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './order-dnd-preview.scss';

const OrderDndPreview = ({layout, itemsData, scenarioId}) => {
	let {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemId = item.id;
	const itemData = itemsData.find((i) => {return i.id === itemId;});

	/* Position */
	var itemsDiv = document.getElementById('orderItems');
	if (itemsDiv) {
		const itemsWidth = itemsDiv.clientWidth;
		const em = (itemsWidth / 17.5);
		const offset = 12 * em;
		style.left = '-' + offset + 'px';
	}
	
	return (
		<div 
			className={'OrderDndPreview ' + (layout ? ' ' + layout : '')} 
			style={style}
		>
			<div className={'OrderDndPreview-item ' + scenarioId}>
				<span>{itemData?.text}</span>
			</div>
		</div>
	);
};

OrderDndPreview.propTypes = {
	layout: PropTypes.string,
	itemsData: PropTypes.array.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default OrderDndPreview;