import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';
import { renderMarkdown } from 'helpers/text-helper';
import { gameUiTexts } from 'data/ui-texts';
import './popup-assignment.scss';

const PopupAssignment = ({title, text, closePopup}) => {

	return (
		<div className='PopupAssignment'>
			<div className='PopupAssignment-overlay'/>
			<div className='PopupAssignment-windowWrapper'>
				<div className='PopupAssignment-windowHeader'>
					<div className='PopupAssignment-infoIcon'/>
					<span>{title}</span>
				</div>
				<div className='PopupAssignment-windowContent'>
					<div className='PopupAssignment-text'>
						<span>{(text ? renderMarkdown(text) : '')}</span>
					</div>
					<div className='PopupAssignment-button'>
						<Button
							text={gameUiTexts.ok}
							classes={['blueRounded']}
							onClick={() => {closePopup();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupAssignment.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default PopupAssignment;