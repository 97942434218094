import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {defaultMaxPoints} from 'data/points-data';
import {
	getTaskData,
	checkIfShowHintBtn,
	checkIfGroupHasCompletedTask, 
	calculateErrors, 
	calculatePoints, 
	getNextTaskNumberIfAble
} from 'helpers/game-helper';
import Button from 'components/ui/button/button';
import Sort from 'components/game-engines/sort/sort';
import MultipleChoice from 'components/game-engines/multiple-choice/multiple-choice';
import Order from 'components/game-engines/order/order';
import InputAnswer from 'components/game-engines/input-answer/input-answer';
import PaperDoll from 'components/game-engines/paper-doll/paper-doll';
import SpotErrors from 'components/game-engines/spot-errors/spot-errors';
import Pairs from 'components/game-engines/pairs/pairs';
import PopupAssignmentHint from 'components/ui/popup-assignment-hint/popup-assignment-hint';
import './assignment.scss';

const Assignment = (
	{
		group, 
		groups,
		scenarioId, 
		areaId, 
		caseId,
		taskNumber, 
		updateGroup,
		setPopupFileData,
		setPopupData,
		setTaskNumber,
		setCasesId
	}) => {

	// Hint popup 
	const [showHintBtn, setShowHintBtn] = useState(false);
	const [showHintPopup, setShowHintPopup] = useState(false);

	// Task data 
	const taskData = getTaskData(scenarioId, areaId, caseId, taskNumber);
	let timeout = null;
	
	/* Update if new task */
	useEffect(() => {
		/* Hide hint button and hint popup */
		setShowHintBtn(false);
		setShowHintPopup(false);

		/* Check if show hint button */
		if (taskData && (taskData.hintText || taskData.hintImage)) {
			if (checkIfShowHintBtn(group.id, groups) === true) {
				setShowHintBtn(true);
			}
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskNumber]);

	useEffect(() => {
		// Clear timeout if any on unmount
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* No data found - return null */
	if (!taskData) return null;

	/* Check if task is completed */
	const taskIsCompleted = checkIfGroupHasCompletedTask(taskData.taskId, group);

	/* Get player data for this task */
	let playerTaskData = null;
	let attemptNumber = 1;
	let awardedPoints = null;
	if (
		group.assignments &&
		group.assignments.some((a) => {
			return a.taskId === taskData.taskId;
		}) 
	) {
		const groupAssignment = group.assignments.find((a) => {
			return a.taskId === taskData.taskId;
		});
		if (taskIsCompleted) {
			awardedPoints = groupAssignment.points;
		}
		if (groupAssignment.attempts && groupAssignment.attempts.length > 0) {
			playerTaskData = groupAssignment.attempts[groupAssignment.attempts.length - 1];
			attemptNumber = groupAssignment.attempts.length + 1;
		}
	}
	

	/* Get max possible / awarded points */
	let maxPoints = defaultMaxPoints;
	if (taskIsCompleted) {
		maxPoints = awardedPoints;
	} else {
		if (playerTaskData) {
			const groupAssignment = group.assignments.find((a) => {
				return a.taskId === taskData.taskId;
			});

			/* Calculate errors */
			let errors = calculateErrors(taskData.type, groupAssignment.attempts);
			if (taskData.type === 'order') errors += 1;

			/* Calculate max possible points (i.e. if they make no more mistakes) */
			 maxPoints = calculatePoints(taskData.type, errors, taskData.maxPoints);
		}
	}

	/* Task type component */
	let TaskComponent = null;
	if (taskData.type === 'sort') TaskComponent = Sort;
	else if (taskData.type === 'order') TaskComponent = Order;
	else if (taskData.type === 'input-answer') TaskComponent = InputAnswer;
	else if (taskData.type === 'multiple-choice') TaskComponent = MultipleChoice;
	else if (taskData.type === 'paper-doll') TaskComponent = PaperDoll;
	else if (taskData.type === 'spot-errors') TaskComponent = SpotErrors;
	else if (taskData.type === 'pairs') TaskComponent = Pairs;
	
	/**
	 * Handle update task
	 * @param {string} type 
	 * @param {object} taskAnswerData 
	 */
	const handleUpdateTask = (type, taskAnswerData) => {
		/* Get group data for task */
		let groupAssignmentsData = (group.assignments ? group.assignments : []);
		let currentAssignmentIndex = groupAssignmentsData.findIndex((a) => {
			return a.taskId === taskData.taskId;
		});
		if (currentAssignmentIndex < 0) {
			/* First attempt */
			groupAssignmentsData.push({
				isCompleted: false,
				taskId: taskData.taskId,
				type: type,
				attempts: [{
					...taskAnswerData
				}]
			});	
		} else {
			/* Not first attempt */
			groupAssignmentsData[currentAssignmentIndex].attempts.push({...taskAnswerData});
		}

		/* Update group data */
		updateGroup({assignments: groupAssignmentsData});
	};


	/**
	 * Handle complete task
	 */
	const handleCompleteTask = (type, taskAnswerData) => {
		/* Get group data for task */
		let groupAssignmentsData = (group.assignments ? group.assignments : []);
		const currentAssignmentIndex = groupAssignmentsData.findIndex((a) => {
			return a.taskId === taskData.taskId;
		});

		/* Attempts data */
		let attempts = [];
		if (currentAssignmentIndex >= 0) {
			attempts = groupAssignmentsData[currentAssignmentIndex].attempts;
		}
		attempts.push({...taskAnswerData});

		/* Calculate errors */
		const errors = calculateErrors(type, attempts);
		
		/* Calculate points */
		const points = calculatePoints(type, errors, taskData.maxPoints, taskAnswerData);

		if (currentAssignmentIndex < 0) {
			/* First time group updates/completes task */
			groupAssignmentsData.push({
				isCompleted: true,
				taskId: taskData.taskId,
				type: type,
				points: points,
				attempts: attempts
			});
		} else {
			/* Not first time group completes task */
			groupAssignmentsData[currentAssignmentIndex].isCompleted = true;
			groupAssignmentsData[currentAssignmentIndex].points = points;
			groupAssignmentsData[currentAssignmentIndex].attempts = attempts;
		}

		// Open assignment popup if popup text exists in assignment data
		if (taskData.popupText) {
			timeout = setTimeout(() => {
				setPopupData({text: taskData.popupText, title: taskData.title});
			}, 500);
		}

		/* Update group data */
		updateGroup({assignments: groupAssignmentsData});
	};

	const caseTasks = scenariosData[scenarioId].tasksData[areaId].filter((task) => {return task.caseId === caseId;});
	const nextTaskNumber = getNextTaskNumberIfAble(taskNumber, caseTasks);

	return (
		<div className={'Assignment ' + taskData.type}>
			<div className={'Assignment-header ' + scenarioId}>
				<div className='Assignment-title'>
					<span>{taskData.title}</span>
				</div>
				<div className='Assignment-maxPoints'>
					<span>{gameUiTexts.points + ': ' + maxPoints}</span>
				</div>
				<div className='Assignment-hint'>
					{showHintBtn && <Button
						classes={['blueRounded', 'hint', scenarioId]}
						text={gameUiTexts.hint}
						onClick={() => {setShowHintPopup(true);}}
					/>}
				</div>
			</div>
			<div className="Assignment-content">
				{TaskComponent && <TaskComponent 
					scenarioId={scenarioId}
					isCompleted={taskIsCompleted}
					attemptNumber={attemptNumber}
					playerTaskData={playerTaskData}
					taskData={taskData}
					setPopupFileData={setPopupFileData}
					handleUpdateTask={handleUpdateTask}
					handleCompleteTask={handleCompleteTask}
				/>}
				{(nextTaskNumber && taskIsCompleted) && 
					<div className='Assignment-navigation'>
						<Button 
							text={gameUiTexts.nextAssignment}
							classes={['blueRounded', 'next', scenarioId]}
							onClick={() => {setTaskNumber(nextTaskNumber);}}
						/>
					</div>
				}
				{(!nextTaskNumber && taskIsCompleted) && 
					<div className='Assignment-navigation'>
						<div className='Assignment-backToOffice'>
							<Button 
								text={gameUiTexts.continueBtn}
								classes={['blueRounded', 'next', scenarioId]}
								onClick={() => {setCasesId(null);}}
							/>
						</div>
					</div>
				}
			</div>

			{showHintPopup &&
				<PopupAssignmentHint 
					title={taskData.title}
					text={taskData.hintText}
					image={taskData.hintImage}
					togglePopup={setShowHintPopup}
				/>}
		</div>
	);
};

Assignment.propTypes = {
	group: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	scenarioId: PropTypes.string.isRequired,
	areaId: PropTypes.string.isRequired,
	caseId: PropTypes.number.isRequired,
	taskNumber: PropTypes.number.isRequired,
	updateGroup: PropTypes.func.isRequired,
	setPopupFileData: PropTypes.func.isRequired,
	setPopupData: PropTypes.func.isRequired,
	setTaskNumber: PropTypes.func.isRequired,
	setCasesId: PropTypes.func.isRequired,
};

export default Assignment;
