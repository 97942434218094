import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {errorUiTexts, adminUiTexts} from 'data/ui-texts';
import {scenarioIds, scenariosData} from 'data/scenarios-data';
import Button from 'components/ui/button/button';
import './popup-create-school.scss';

const PopupCreateSchool = ({schools, createSchool, closePopup}) => {
	/* Input: name, email, password, scenario ids */
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [selectedScenarioIds, setSelectedScenarioIds] = useState(JSON.parse(JSON.stringify(scenarioIds)));

	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Error message */
	const [errorMessage, setErrorMessage] = useState(null);

	/**
	 * Handles form input
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		setErrorMessage(null);
		if (event.target.name === 'name') {
			setName(event.target.value);
		} else if (event.target.name === 'email') {
			setEmail(event.target.value);
		} else if (event.target.name === 'password') {
			setPassword(event.target.value);
		}
	};

	/**
	 * Toggle scenario id
	 * @param {string} scenarioId 
	 */
	const toggleScenarioId = (scenarioId) => {
		let newSelectedScenarioIds = JSON.parse(JSON.stringify(selectedScenarioIds));
		const scenarioIndex = newSelectedScenarioIds.indexOf(scenarioId);
		if (scenarioIndex >= 0) {
			newSelectedScenarioIds.splice(scenarioIndex, 1);
		} else {
			newSelectedScenarioIds.push(scenarioId);
		}
		setSelectedScenarioIds(newSelectedScenarioIds);
	};

	/**
	 * Create school
	 */
	const handleCreateSchool = () => {
		if (isLoading) return;

		setIsLoading(true);
		setErrorMessage(null);

		let newErrMsg = null;

		/* Check input */
		if (!name || !email || !password) {
			newErrMsg = errorUiTexts.emptyFields;
		/* Check school name */
		} else if (schools && schools.length > 0) {
			if (schools.some((s) => {
				return s.name.trim().toLowerCase() === name.trim().toLowerCase();
			})) {
				newErrMsg = errorUiTexts.schoolNameExists;
			};
		}

		if (newErrMsg) {
			setErrorMessage(newErrMsg);
			setIsLoading(false);
		} else {
			createSchool(name, email, password, selectedScenarioIds).then((response) => {
				if (response.status === 'success') {
					closePopup();
				} else {
					setIsLoading(false);
					console.error(response);
				}
			});
		}
	};

	return (
		<div className='PopupCreateSchool' onClick={() => {closePopup();}}>
			<div className='PopupCreateSchool-window' onClick={(e) => {e.stopPropagation();}}>
				<div className='PopupCreateSchool-title'>
					<span>{adminUiTexts.createSchoolPopup.title}</span>
				</div>
				<form className='PopupCreateSchool-form' onSubmit={handleCreateSchool}>
					<div className={'PopupCreateSchool-input'}>
						<div className='PopupCreateSchool-inputTitle'>
							<span>{adminUiTexts.createSchoolPopup.schoolName}</span>
						</div>
						<input
							name='name'
							type='text'
							placeholder={adminUiTexts.createSchoolPopup.school}
							autoComplete='section-inputAnswer name'
							value={name ? name : ''}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
					<div className={'PopupCreateSchool-input'}>
						<div className='PopupCreateSchool-inputTitle'>
							<span>{adminUiTexts.createSchoolPopup.email}</span>
						</div>
						<input
							name='email'
							type='text'
							placeholder={adminUiTexts.createSchoolPopup.email}
							autoComplete='section-inputAnswer email'
							value={email ? email : ''}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
					<div className={'PopupCreateSchool-input'}>
						<div className='PopupCreateSchool-inputTitle'>
							<span>{adminUiTexts.createSchoolPopup.password}</span>
						</div>
						<input
							name='password'
							type='text'
							placeholder={adminUiTexts.createSchoolPopup.password}
							autoComplete='section-inputAnswer password'
							value={password ? password : ''}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
					<div className="PopupCreateSchool-input scenarios">
						<div className='PopupCreateSchool-inputTitle'>
							<span>{adminUiTexts.createSchoolPopup.games}</span>
						</div>
						<div className="PopupCreateSchool-scenarios">
							{scenarioIds.map((scenarioId) => {
								const scenarioData = scenariosData[scenarioId];
								if (!scenarioData) return null;
								return (
									<div 
										key={scenarioId} 
										className={'PopupCreateSchool-scenario' + 
											(selectedScenarioIds.includes(scenarioId) ? ' selected' : '')}
										onClick={() => {toggleScenarioId(scenarioId);}}
									>
										<span>{scenarioData.title}</span>
									</div>
								);
							})}
						</div>
					</div>
					<div className="PopupCreateSchool-submitButton">
						<Button
							isDisabled={isLoading}
							text={adminUiTexts.createSchoolPopup.create}
							classes={['login']}
							onClick={() => {handleCreateSchool();}}
						/>
					</div>
				</form>

				{errorMessage &&
					<div className='PopupCreateSchool-errorMessage'>
						<span>{errorMessage}</span>
					</div>
				}
			</div>
		</div>
	);
};

PopupCreateSchool.propTypes = {
	schools: PropTypes.array,
	createSchool: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default PopupCreateSchool;