import React from 'react';
import { Link } from 'react-router-dom';
import {scenariosData} from 'data/scenarios-data';
import Logos from 'components/ui/logos/logos';
import './select-game.scss';

const SelectGame = () => {
	return (
		<div className="SelectGame">
			<Logos />
			<div className='SelectGame-background'>
				<div className='SelectGame-backgroundTop' />
				<div className='SelectGame-backgroundDivider'/>
				<div className='SelectGame-backgroundBottom'/>
				<div className='SelectGame-backgroundOverlay'/>
			</div>
			<div className="SelectGame-games">
				{Object.keys(scenariosData).reverse().map((key)=>{
					return (
						<Link key={key} to={scenariosData[key].url} className={'SelectGame-game ' + key} />
					);
				})}
			</div>
		</div>
	);
};


export default SelectGame;