import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {schoolUiTexts } from 'data/ui-texts';
import YouTube from 'react-youtube';
import './popup-video.scss';

const PopupVideo = ({closePopup}) => {
	/* Video state and ref */
	const [videoState, setVideoState] = useState('loading');
	const [videoRef, setVideoRef] = useState(null);

	/* Video id */
	const videoId = appConfig.youtubeVideoId;

	/**
	 * Start video
	 * @param {object} e 
	 * @returns 
	 */
	const handlePlay = (e) => {
		e.stopPropagation();
		if (videoState === 'loading') return;
		if (videoRef && videoRef.playVideo)  videoRef.playVideo();
	};

	/**
	 * Video ended
	 */
	const videoEnded = () => {
		setVideoState('ended');

		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	};

	

	return (
		<div className='PopupVideo' onClick={() => {closePopup();}}>
			<div className='PopupVideo-window' onClick={(e) => {e.stopPropagation();}}>
				<div className='PopupVideo-title'>
					<span>{schoolUiTexts.video}</span>
				</div>
				<div className='PopupVideo-closeBtn' onClick={() => {closePopup();}} />
				<div className="PopupVideo-videoWrap">
					<YouTube 
						className={'PopupVideo-video ' + videoState} 
						videoId={videoId} 
						onReady={(e) => {setVideoRef(e.target); setVideoState('ready');}}
						onPlay={() => {setVideoState('playing');}}
						onEnd={() => {videoEnded();}}
						opts={{
							height: '100%', 
							width: '100%', 
							playerVars: {
								controls: 1,
							}
						}}
					/>
					<div 
						className={'PopupVideo-videoPlayBtn ' + videoState} 
						onClick={(e) => {handlePlay(e);}} 
					/>
				</div>
			</div>
		</div>
	);
};

PopupVideo.propTypes = {
	closePopup: PropTypes.func.isRequired,
};

export default PopupVideo;