import React from 'react';
import './area-button.scss';
import PropTypes from 'prop-types';

const AreaButton = ({ isComplete = false, areaButtonId, name, onClick, scenarioId }) => {
	return (
		<div className='AreaButton' onClick={() => {onClick();}}>
			<div className={'AreaButton-circle ' + scenarioId}>
				<div className={'AreaButton-icon ' + areaButtonId}/>
			</div>
			<div className='AreaButton-barWrapper'>
				<div className={'AreaButton-bottomBar ' + scenarioId}/>
				<div className={'AreaButton-bar ' + scenarioId}>
					<span>{name}</span>
				</div>
			</div>
			{isComplete && 
				<div className='AreaButton-complete'/>
			}
		</div>
	);
};

AreaButton.propTypes = {
	isComplete: PropTypes.bool,
	areaButtonId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default AreaButton;