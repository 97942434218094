import appConfig from 'config/app.config';

// Send API request to backend
export default function apiHelper(endpoint, body) {
	let url = appConfig.apiURL + endpoint;
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	let fetchConfig = {
		method: 'POST',
		mode: 'cors',
		headers: myHeaders,
		cache: 'default',
		body: JSON.stringify({...body, env: appConfig.env})
	};

	let promise = new Promise((resolve, reject)=>{
		fetch(url, fetchConfig).then(
			(response)=>{
				response.json().then(
					(data) => {
						resolve(data);
					},
					(rejection) => {
						console.error('api rejection: ' + rejection);
						reject({status: 'error', error: 'fetch-error'});
					}
				);
			},
			(error)=>{
				reject({status: 'error', error: error});
			}
		);
	});

	return promise;
}