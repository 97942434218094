const scenarioIds = [
	'game1', 'game2'
];

const scenariosData = {
	game1: {
		title: 'HTX',
		url: 'htx',
		gamePagesData: require('data/game1/game-pages-data').gamePagesData,
		tasksData: {
			build: require('data/game1/assignments/build-tasks-data').tasksData,
			design: require('data/game1/assignments/design-tasks-data').tasksData,
			it: require('data/game1/assignments/it-tasks-data').tasksData,
			prep: require('data/game1/assignments/prep-tasks-data').tasksData,
			production: require('data/game1/assignments/production-tasks-data').tasksData,
			research: require('data/game1/assignments/research-tasks-data').tasksData
		}
	},
	game2: {
		title: 'HHX / EDU',
		url: 'hhxedu',
		gamePagesData: require('data/game2/game-pages-data').gamePagesData,
		tasksData: {
			sales: require('data/game2/assignments/sales-tasks-data').tasksData,
			marketing: require('data/game2/assignments/marketing-tasks-data').tasksData,
			it: require('data/game2/assignments/it-tasks-data').tasksData,
			management: require('data/game2/assignments/management-tasks-data').tasksData,
			economy: require('data/game2/assignments/economy-tasks-data').tasksData,
		}
	}
};



export {
	scenarioIds,
	scenariosData
};