import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import { getCookie, setCookie } from 'helpers/cookie-helper';
import Login from './login';
import Loading from 'components/loading/loading';
import { errorUiTexts, loginUiTexts } from 'data/ui-texts';
import SchoolController from 'components/users/school-controller';
import GroupController from 'components/users/group-controller';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			isSchool: false,
			userId: null,
			schools: []
		};
		this.unsubscribeSchools = null;
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Subscribe to schools */
		this.subscribeToSchools().then(() => {
			/* Check if logged in */
			this.checkIfLoggedIn();
		}).catch((error) => {
			console.error(error);
		});
		
		/* Update background */
		this.props.updateBackground('login', this.props.scenarioId);
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeSchools !== null) this.unsubscribeSchools();
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				if (user.email) {
					/* Logged in as admin */
					this.handleLogout();
				} else {
					/* Ok to cookies */
					const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
					if (!cookieConsent) {
						setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
					}

					const isSchool = this.state.schools.some((s) => {return s.id === user.uid;});
					if (isSchool) {
					/* Logged in as school */
						this.setState({isLoggedIn: true, isLoading: false, userId: user.uid, isSchool: true});
					} else {
					/* Logged in as group */
						this.setState({isLoggedIn: true, isLoading: false, userId: user.uid});
					}
				}
			} else {
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					isSchool: false,
					email: null,
				});
			}
		});	
	};

	/**
	 * Subscribe to schools
	 */
	subscribeToSchools = () => {
		if (this.unsubscribeSchools !== null) this.unsubscribeSchools();

		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			this.unsubscribeSchools = db.collection(appConfig.schoolsDbName).onSnapshot(
				(querySnapshot) => {
					let schools = [];
					querySnapshot.forEach((doc) => {
						schools.push({id: doc.id, ...doc.data()});
					});
					this.setState({schools: schools});
					resolve();
				},
				(error) => {
					this.setState({feedback: errorUiTexts.couldNotGetSchool});
					reject(error);
				}
			);
		});
	};	

	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({isLoggedIn: false, isSchool: false, userId: null});
	};


	/**
 	* Render component
 	*/
	render = () => {
		/* Waiting for loading done */
		if (this.state.isLoading) {
			return (
				<Loading
					loadErrMsg={loginUiTexts.loadingGame} 
					handleLogout={this.handleLogout}
				/>
			);
		}

		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<Login scenarioId={this.props.scenarioId} schools={this.state.schools} />
			);
		}

		/* User is logged in, get controller component for school / group */
		let UserComponent = (this.state.isSchool ? SchoolController : GroupController);

		return (
			<UserComponent 
				scenarioId={this.props.scenarioId}
				userId={this.state.userId}
				schools={this.state.schools}
				handleLogout={this.handleLogout}
				updateBackground={this.props.updateBackground}
				handlePlayMusic={this.props.handlePlayMusic}
			/>
		);
	};
}

LoginController.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default LoginController;