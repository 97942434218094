import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { loginUiTexts, gameUiTexts } from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './landing-page-group.scss';

const LandingPageGroup = (props) => {
	/* Props */
	const {game, schoolData, scenarioId, resetGameProgress, handleGoToPage, handleLogout, updateBackground} = props;

	/* Page title */
	const title = (game && game.status === 'done' 
		? gameUiTexts.thankYouForPlaying
		: gameUiTexts.welcomeTo
	);
	
	useEffect(() => {
		// Set background image id
		updateBackground('login', scenarioId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="LandingPageGroup">
			<div className="LandingPageGroup-welcome"><span>{title}</span></div>
			<div className='LandingPageGroup-logoWrapper'>
				<Logo scenarioId={scenarioId} page="landing"/>
				{schoolData && <div className="LandingPageGroup-schoolName">
					<span>{schoolData.name}</span>
				</div>}
			</div>


			{/* Start game btn */}
			{game.status === 'open' && <div className='LandingPageGroup-startBtn'>
				<Button 
					classes={['blueLight', scenarioId, 'responsive']}
					text={gameUiTexts.goToOffice}
					onClick={() => {handleGoToPage('game');}}
				/>
			</div>}
				
			{/* Game not begun / game over */}
			{game.status !== 'open' &&
				<div className='LandingPageGroup-gameNotStarted'>
					<span>{game.status === 'ready' ? gameUiTexts.gameNotBegun : gameUiTexts.gameOver}</span>
				</div>
			}

			{/* Qr code (if game is done) */}
			{game.status === 'done' && <div className="LandingPageGroup-qrCodeWrap">
				<div className="LandingPageGroup-qrCodeText">
					<span>{gameUiTexts.qrCodeInfo}</span>
				</div>
				<div className={'LandingPageGroup-qrCodeImage ' + game.scenarioId} />
			</div>}



			{/* Logout button */}
			<div className="LandingPageGroup-logoutBtn">
				<Button 
					classes={['login']} 
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Reset game btn */}
			{(appConfig.env === 'development' || appConfig.env === 'test') && 
				<div className="LandingPageGroup-resetBtn">
					<Button 
						classes={['login']} 
						text={gameUiTexts.resetBtn}
						onClick={() => {resetGameProgress();}}
					/>
				</div>
			}
		</div>
	);
};

LandingPageGroup.propTypes = {
	game: PropTypes.object.isRequired,
	schoolData: PropTypes.object,
	scenarioId: PropTypes.string.isRequired,
	resetGameProgress: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired
};

export default LandingPageGroup;
