import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { sortArrayByLikeness } from 'helpers/array-helper';
import './input-search.scss';

const InputSearch = ({schools, focusPassword, setSelectedSchoolId}) => {
	const [search, setSearch] = useState('');
	const [currentSearchResult, setCurrentSearchResult] = useState(null);
	const [currentFocusId, setCurrentFocusId] = useState(0);

	/**
	 * Close search result if clicked outside of element
	 */
	const handleClickOutside = (event) => {
		const listRef = document.getElementById('schoolInput');
		const inputRef = document.getElementById('options');
		
		if ((listRef && !listRef.contains(event.target)) && (inputRef && !inputRef.contains(event.target))) {
			setCurrentSearchResult(null);
		}
	};

	/**
	 * Handles enter button for list input
	 * @param {number} index 
	 */
	const handleEnter = (index) => {
		if (currentSearchResult[index]) {
			setSelectedSchoolId(currentSearchResult[index].id);
			setSearch(currentSearchResult[index].name);
			setCurrentSearchResult(null);

			focusPassword();
		}
	};

	/**
	 * Handles key events for search list
	 * @param {object} event 
	 */
	const handleKeyEvent = (event) => {
		const searchList = currentSearchResult;

		if (event.key === 'Enter' || event.key === 'Tab') {
			event.preventDefault();
			handleEnter(currentFocusId);
		} else if (searchList) {
			let newFocusId = currentFocusId ? currentFocusId : 0;

			let alignToTop = false;

			if (event.key === 'ArrowUp') {
				newFocusId = newFocusId - 1 < 0 ? searchList.length - 1 : newFocusId - 1;
				alignToTop = true;
			} else if (event.key === 'ArrowDown') {
				newFocusId = Math.min((newFocusId + 1) % searchList.length, searchList.length - 1);
				alignToTop = false;
			}

			if (newFocusId !== currentFocusId) {
				const element = document.getElementById(newFocusId);

				const elementWrapper = document.getElementById('options');
				let isInView = true;
				if (elementWrapper && element) {
					const wrapperRect = elementWrapper.getBoundingClientRect();
					const elementRect = element.getBoundingClientRect();
					isInView = elementRect.top > wrapperRect.top && elementRect.bottom < wrapperRect.bottom;
				}

				if (!isInView) {
					element.scrollIntoView(alignToTop);
				}
				setCurrentFocusId(newFocusId);
			}
		}
	};

	/**
	 * Handles setting current value and finding array by likeness to search term
	 * @param {object} event 
	 * @returns 
	 */
	const handleInput = (event) => {
		const value = event.target.value;

		setSearch(value);
		// Search only starts with 2 or more letters
		if (value.length < 2) {
			setSelectedSchoolId(null);
			setCurrentSearchResult(null);
			return;
		}
		// Search through schools list, 
		// find all schools that contain current string value and order according to likeness
		let newSearch = sortArrayByLikeness(schools, value, 'name');
		if (newSearch.length === 0) {
			newSearch = null;
		}
		
		// If current search matches with top result from list, set schoolId
		if (newSearch && newSearch[0].name.toLowerCase() === value.toLowerCase()) {
			setSelectedSchoolId(newSearch[0].id);
		} else {
			setSelectedSchoolId(null);
		}
		setCurrentSearchResult(newSearch);
	};

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
		// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='InputSearch' onKeyDown={(event) => {handleKeyEvent(event);}}>
			<input
				id='schoolInput'
				autoComplete='off'
				className='InputSearch-schoolInput'
				name='school'
				type='text'
				placeholder={'Skole'}
				value={search}
				onChange={(event) => {
					handleInput(event);
				}}
				// Preventing arrow keys up and down skipping back and forth in text
				onKeyDown={(event) => {
					if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
						event.preventDefault();
						return;
					}
				}}
			/>
			<div id={'options'} 
				className={
					'InputSearch-optionsWrapper' + 
					(currentSearchResult && currentSearchResult.length > 0 ? ' isOpen' : '')
				}
			>
				{(currentSearchResult && currentSearchResult.length > 0) &&
				currentSearchResult.map((searchResult, index) => {
					let isInFocus = false;
					if (index === currentFocusId) {
						isInFocus = true;
					}

					return (
						<div
							key={index} 
							id={index}
							tabIndex={index}
							className={'InputSearch-option' + (isInFocus ? ' focus' : '')}
							onClick={() => {handleEnter(index); focusPassword();}}
						>
							<span>{searchResult.name}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

InputSearch.propTypes = {
	schools: PropTypes.array.isRequired,
	focusPassword: PropTypes.func.isRequired,
	setSelectedSchoolId: PropTypes.func.isRequired,
};

export default InputSearch;