const taskTemplates = require('../../task-templates');

const tasksData = [		
	/* case 1 */
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'production-paper-doll-equipment',
		layout: 'production-equipment',
		text: `Sørg for at have det rigtige udstyr med i produktionen. Vælg det rigtige udstyr. Tryk OK, når I har det.`,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'brains', title: 'Hjernen', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'microscope', title: 'Mikroskop', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'lab-coat', title: 'Kittel', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'safety-glasses', title: 'Sikkerhedsbriller', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'green-transition', title: 'Grøn omstilling i en tankeboble', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'plastic-gloves', title: 'Plastikhandsker', isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'tie', title: 'Slips'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'keys', title: 'Nøgler'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'spray-bottle', title: 'Sprayflaske'
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'production-spot-errors-laboratory',
		layout: 'production-laboratory',
		text: `Orden er ekstremt vigtig i et laboratorium. Klik på de steder, hvor der er problemer i laboratoriet. <br/> Tryk OK, når I mener, I har dem alle. `,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'paper-on-floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'broken-lamp'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'box-with-knife'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'clothes'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sales-man'}),
		]
	}),
	/* case 2 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'production-multiple-choice-ph-value',
		text: `Når produktionen starter i den nye tank, skal pH'en i tanken justeres. Enzymerne skal have et miljø på pH = 8, men er ved start pH = 3. Hvad kan der tilføres for at øge pH'en?`,
		popupText: 'Base (pH > 7) og vand (pH = 7) vil begge få pH\'en til at stige. Olie og mælk vil ødelægge produktionen af enzymer.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Syre', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Base',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Vand	',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Olie', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Mælk', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'production-input-answer-sugar-volumen',
		text: `Når pH'en er justeret til 8, blander man enzymerne med sukker (glukose) for at speede produktionen op. Optimalt skal der være 8% glukose i tanken. Volumen af tanken er 50.000 L. Hvor mange liter "L" glukose skal der tilsættes?`,
		popupText: '8% af voluminet er 50000 L * 0,08 = 4000 L',
		answer: 4000,
		unit: 'L'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'production-input-answer-sugar-mass',
		text: `Massefylden (densiteten) af glukosen er 1,56 kg/L. Hvor mange ton glukose skal der hældes i tanken?`,
		popupText: 'Massen af glukosen er m = 1,56 kg/L * 4000 L = 6240 kg = 6,24 ton',
		answer: 6.24,
		unit: 'ton'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 4,
		title: 'Opgave 4',
		taskId: 'production-multiple-choice-average-temp',
		text: `Temperaturen i tanken skal holdes på 37 grader Celsius. Temperaturen måles hvert minut, så varmelegemet kan tændes og slukkes efter behov. Kig på grafen over målinger. Hvad var gns. temperaturen over de 60 minutter? `,
		image: 'production-average-temp',
		popupText: 'Gennemsnitstemperaturen er summen af alle punkternes temperatur divideret med de 60 minutter, hvilket giver 37 grader Celsius',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '35', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '36', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '37', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '38', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '39', 
			}),

		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 5,
		title: 'Opgave 5',
		taskId: 'production-multiple-choice-energy-source',
		text: `Tanken kan opvarmes med enten gas eller el.
		<br /><br />Der skal bruges 45 kWh til at varme indholdet i tanken op i to timer.<br />
		Der skal bruges 10 m<sup>3</sup> naturgas til at varme indholdet op i to timer.
		<br /><br />
		1 kWh (kilowatttime) koster i gennemsnit 3,57 kr.<br />
		1 m<sup>3</sup> naturgas koster i gennemsnit 27,15 kr. 
		<br /><br />
		Hvad vil være den billigste måde at opvarme tanken på?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Det er billigst med el', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det er billigst med gas', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Prisen er det samme for både el og gas', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 6,
		taskId: 'production-order-process',
		title: 'Opgave 6',
		text: `Der skal laves en proces for, hvordan I introducerer nye kemikalier i produktionen. Sæt følgende procestrin i den rigtige rækkefølge.`,
		image: 'process',
		hintImage: 'production-process',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'risc', 
				text: `Risikovurdering`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'safety', 
				text: `Sikkkerhedsforanstaltninger`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'intro', 
				text: `Oplæring og introduktion`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'control', 
				text: `Tilsyn og kontrol`,
				correctOrderNumbers: [4]
			})
		]
	}),
	/* case 3 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'production-multiple-choice-education',
		text: `Hvis der skal flere robotter i produktionen, så kræver det efteruddannelse af medarbejderne. Hvad vil være det bedste område at uddanne dem indenfor?`,
		image: 'production-technician',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Industrioperatør', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Maskinmester', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Mekaniker', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Elektriker', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'production-multiple-choice-investing',
		text: `Hvorfor er det en god ide at investere i robotteknologi?`,
		image: 'production-technicians-lab',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Spare penge på løn', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Tidsbesparelse i produktion', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Mere bæredygtigt', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Lettere at administrere end mennesker', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Kræver mindre uddannelse', 
			}),
		],
	}),
	/* case 4 */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'production-multiple-choice-robot-colaboration',
		text: `En del af produktionen kræver robotter, der kan arbejde sammen med jeres medarbejdere. Hvilken type robot skal I vælge til opgaven?`,
		popupText: 
`En **industrirobot** er en automatisk styret, reprogrammérbar, bredt
anvendelig robot om bruges til industrielle automationsløsninger afskærmet fra
mennesker.

En **servicerobot** gennemfører nyttige
serviceopgaver for mennesker eller udstyr, der ikke inkluderer industrielle automationsløsninger. 

En **kollaborativ** robot arbejder 
side om side med mennesker, hvor robotten identificerer
arbejdsrisici for mennesket og reagerer på disse.`,
		image: 'production-technician-robot-coworker',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Industrirobot',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Servicerobot',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Kollaborativ robot',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'production-multiple-choice-robot-graph',
		text: `Kig på billedet. Hvor mange robotter var der pr. 50.000 ansatte i Danmark i 2019?`,
		image: 'production-technicians-robot-graph',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '243',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '486', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '972', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '1215',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '1458', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'production-multiple-choice-multiple-graphs',
		text: `Kig på grafen, som viser de fire områders mulighed for automatisering. Hvilken afdeling har størst potentiale?`,
		image: 'production-technicians-multiple-graphs',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Produktion',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Montage og anlæg', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Lager og pakning', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Kontoropgaver og administrative processer',
			}),
		],
	}),
	/* case 5 */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1,
		taskId: 'production-order-programming',
		title: 'Opgave 1',
		text: `I skal udvikle en ny softwarerobot, som skal aflaste personale og spare virksomheden penge. Vurdér, hvilke typer opgaver en softwarerobot vil være bedst til, og sortér dem i rækkefølge fra bedst til værst.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'telephone', 
				text: `Besvare telefonen`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'lights-off', 
				text: `Sørge for at slukke lyset`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'timeuse', 
				text: `Automatisk registrering af timeforbrug`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'economy', 
				text: `Automatisk indtastning af kvitteringer`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'social-activities', 
				text: `Planlægning af sociale aktiviteter.`,
				correctOrderNumbers: [5]
			})
		]
	}),
	/* case 6 */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 6,
		taskNumber: 1,
		taskId: 'production-order-robot-tea',
		title: 'Opgave 1',
		text: 'Forestil dig, at du skal få en robot til at lave en kop te. Husk: En robot udfører nøjagtigt det du beder den om, hverken mere eller mindre. Sæt kommandoerne i den korrekte rækkefølge.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'lift-kettle', 
				text: `Løft elkoger`,
				correctOrderNumbers: [1, 9]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'move', 
				text: `Flyt elkoger hen under vandhanen`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'turn-on-water', 
				text: `Tænd vandet`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'turn-off-water', 
				text: `Sluk vandet når elkoger er fyldt`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'move-kettle-to-correct-place', 
				text: `Sæt elkoger på plads`,
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'turn-on-kettle', 
				text: `Tænd elkogeren`,
				correctOrderNumbers: [6]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'take-the', 
				text: `Tag fat i te-brev`,
				correctOrderNumbers: [7]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'place-the-into-kop', 
				text: `Put te-brev i kop`,
				correctOrderNumbers: [8]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'lift-kettle-again', 
				text: `Løft elkoger`,
				correctOrderNumbers: [9, 1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'pour-the-water-on-top', 
				text: `Hæld vand fra elkoger i kop`,
				correctOrderNumbers: [10]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 6,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'production-multiple-choice-robot-typical-jobs',
		text: `Robotter er kun gode til nogle typer af opgaver. Hvilke tre opgaver har robotter typisk på en fabrik?`,
		popupText: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Montage', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Skæring',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Opsamling og pakning',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Inspektion'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Syning',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Varierede opgaver'
			})
		],
	}),

];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'htx-production-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};