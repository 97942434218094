import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import InputSearch from 'components/ui/input-search/input-search';
import './login-school.scss';

const LoginSchool = (props) => {
	let {
		isLoading,
		scenarioId,
		schoolId,
		password,
		feedback,
		schools,
		handleInput,
		handleLogin,
		selectSchool
	} = props;

	const focusPassword = () => {
		const inputRef = document.getElementById('password');

		inputRef.focus();
	};

	return (
		<div className={'LoginSchool ' + scenarioId}>
			<div className="LoginSchool-title"><span>{loginUiTexts.schoolLogin}</span></div>
			<div className="LoginSchool-body">
			
				<div className='LoginSchool-loginDirection'><span>{loginUiTexts.login + ':'}</span></div>
				<form className="LoginSchool-form" onSubmit={(e) => {handleLogin(e);}}>
					<InputSearch
						focusPassword={focusPassword}
						schools={schools}
						setSelectedSchoolId={selectSchool}
					/>
					<input
						id='password'
						name="password"
						className='LoginSchool-loginInput'
						type="password"
						placeholder={loginUiTexts.password}
						autoComplete="section-schoollogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="LoginSchool-loginBtn">
						<Button 
							isDisabled={!schoolId || !password}
							isLoading={isLoading}
							classes={['login']}
							text={loginUiTexts.login}
							onClick={() => {handleLogin();}}
							type='submit'
						/>
					</div>
				</form>
				<div className="LoginSchool-errorMessage">{feedback}</div> 
			</div>
		</div>
	);
};

LoginSchool.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	scenarioId: PropTypes.string.isRequired,
	schoolId: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	schools: PropTypes.array.isRequired,
	selectSchool: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
	handleLogin: PropTypes.func.isRequired,
};

export default LoginSchool;