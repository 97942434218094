import React from 'react';
import PropTypes from 'prop-types';
import { schoolUiTexts } from 'data/ui-texts';
import './game-code.scss';

const gameCode = ({page, game}) => {
	return (
		<div className={'GameCode ' + page}>
			<span>{schoolUiTexts.gameCode}:</span>
			<span>{game.id}</span>
		</div>
	);
};


gameCode.propTypes = {
	page: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
};

export default gameCode;
