const taskTemplates = require('../../task-templates');

const tasksData = [		
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		taskId: 'prep-order-accident',
		title: 'Opgave 1',
		text: `Når det gælder en alvorlig personulykke, hvad er så den korrekte rækkefølge at gøre nedenstående i? Sæt dem i rækkefølge med første handling øverst. `,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'stop', 
				text: `Stands ulykken`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'safety', 
				text: `Sikr tilskadekomne`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'ambulance', 
				text: `Rekvirer ambulance`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'announce-employees', 
				text: `Udsend melding om ulykke til ansatte`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'announce-supervisors', 
				text: `Anmeld ulykken til Arbejdstilsynet`,
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'prevent', 
				text: `Gennemgå arbejdsforhold for at undgå, at ulykken sker igen`,
				correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'prep-multiple-choice-safety-poster',
		text: `I skal lave en sikkerhedsplakat, der informerer om det rigtige sikkerhedsudstyr i produktionen. Vælg, hvad der skal være på plakaten.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Sikkerhedssko'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Hårnet', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Sikkerhedsbriller', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Høreværn', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Brandslukker', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Refleksvest', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Plastikhandsker', 
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 3,
		taskId: 'prep-sort-shelves',
		layout: 'prep-shelves',
		title: 'Opgave 3',
		text: `Sorter sikkerhedsudstyret, så det står på de rette hylder.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'fire-stuff',
				type: 'image',
				title: `Hylde med brandudstyr`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'first-aid', 
				type: 'image',
				title: `Hylde med førstehjælp`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'safety-equipment',
				type: 'image',
				title: `Hylde med sikkerhedsudstyr`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Brandslukker`,
				type: 'image',
				categoryIds: ['fire-stuff']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Brandtæppe`,
				type: 'image',
				categoryIds: ['fire-stuff']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Førstehjælpskasse`,
				type: 'image',
				categoryIds: ['first-aid']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: 'Hjertestarter',
				type: 'image',
				categoryIds: ['first-aid']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: 'Kittel',
				type: 'image',
				categoryIds: ['safety-equipment']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: 'Plastikhandsker',
				type: 'image',
				categoryIds: ['safety-equipment']
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		taskId: 'prep-sort-extinguishers',
		layout: 'prep-extinguishers',
		title: 'Opgave 1',
		text: `Hvilken type slukningsredskab skal du bruge til forskellige brande? Træk slukningsredskabet op til den rigtige brand. `,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'computer', 
				type: 'image',
				title: `Brand i computer`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'pot', 
				type: 'image',
				title: `Brand i gryde`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'furniture', 
				type: 'image',
				title: `Brand i møbel`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Pulverslukker`,
				type: 'image',
				categoryIds: ['furniture', 'pot']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Brandslange`,
				type: 'image',
				categoryIds: ['furniture']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Brandtæppe`,
				type: 'image',
				categoryIds: ['pot']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `CO<sub>2</sub>-slukker`,
				type: 'image',
				categoryIds: ['computer', 'pot']
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'prep-spot-errors-safety-issues',
		layout: 'prep-safety-issues',
		text: `Hvilke sikkerhedsproblemer kan I spotte her? Tryk på problemerne. <br /> når I tror, I har dem alle.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'firealarm-no-battery'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'no-fire-extinguisher'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hidden-fire-hose'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'blocking-box'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'escape-lamp-not-functional'}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 3,
		taskId: 'prep-order-spotted-fire',
		title: 'Opgave 3',
		text: `Hvis man spotter en brand i virksomheden, hvad skal man så gøre? Sæt handlingerne i den rigtige rækkefølge. Klik OK, når I mener, I har den.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'stop', 
				text: `Udløs brandalarmen`,
				correctOrderNumbers: [1]
			}),			
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'extinguish', 
				text: `Forsøg at slukke ilden (hvis du kan)`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'evacuate', 
				text: `Evakuér bygningen`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'call-112', 
				text: `Ring 1-1-2`,
				correctOrderNumbers: [4]
			}),
		]
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'htx-prep-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};