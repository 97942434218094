const cookieUiTexts = {
	popupText: 'Vi bruger cookies til at forbedre din spiloplevelse. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
	popupBtn: 'Ok',
};

const errorUiTexts = {
	unknownError: 'Fejl: Ukendt fejl.',
	/* Create user (facilitator) */
	emptyFields: 'Du skal udfylde alle felter.',
	emailExists: 'Der findes allerede en bruger med denne email.',
	invalidPassword: 'Dit password skal være på mindst 8 tegn.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	userNotAllowed: 'Denne e-mail er ikke godkendt.',
	userAlreadyCreated: 'Denne e-mail er allerede i brug.',
	/* Login / reset password (facilitator) */
	invalidSchool: 'Ugyldig skole.',
	invalidPassword: 'Ugyldigt password.',
	invalidEmail: 'Din email er ikke gyldig.',
	userNotFound: 'Brugeren findes ikke i systemet.',
	/* Login (group) */
	noGameCodeMatch: 'Ugyldig spilkode.',
	/* Create group */
	invalidCharacters: 'Navnet indeholder et eller flere ugyldige tegn.',
	groupNameTooShort: 'Gruppenavn skal være på mindst 3 tegn.',
	groupNameTooLong: 'Gruppenavn må ikke være længere end 15 tegn.',
	noGroupName: 'Husk at udfylde et gruppenavn.',
	groupNameAlreadyExists: 'Der eksisterer allerede en gruppe med det navn.',
	
	/* Create Game */
	createGameError: 'Fejl i oprettelse af spil',

	/* School */
	schoolNameExists: 'Der findes allerede en skole med det navn.',
	couldNotGetSchool: 'Kunne ikke finde skole',

	/* Game Data */
	noGameData: 'game data not found',
	noGameDataDatabase: 'Error: Game data not in database.',
};

const loginUiTexts = {
	welcome: 'Velkommen til',
	loading: 'Loading',
	schoolLogin: 'SKOLE',
	adminLogin: 'Admin',
	superAdminLogin: 'Super admin',
	groupLogin: 'ELEV',
	gameCode: 'Kodeord',
	groupLoginIntruction: 'Når din lærer opretter et spilforløb, genereres en unik spilkode.',
	login: 'Log ind',
	newGroup: 'Ny gruppe',
	writeGroupName: 'Skriv gruppenavn',
	groupName: 'Gruppenavn',
	create: 'Opret',
	cancel: 'Afbryd',
	email: 'E-mail',
	password: 'Password',
	repeatPassword: 'Gentag password',
	logout: 'Log ud',
	createNewUser: 'Opret ny bruger',
	create: 'Opret',
	newUserCreated: 'Din bruger er blevet oprettet, du kan nu logge ind.',
	backToLogin: 'Tilbage til login',
	resetPassword: 'Nulstil password',
	reset: 'Nulstil',
	passwordIsReset: 'Du har fået tilsendt en e-mail med et link til at nulstille dit password.',
	loggedInAs: 'Logget ind som',
	useGameCode: 'Indtast spilkoden:',
	loggingIn: 'Logger ind ...',
	loadingGame: 'Henter spil ...',
};

const adminUiTexts = {
	admin: 'Admin',
	email: 'E-mail',
	status: 'Status',
	created: 'Oprettet',
	delete: 'Slet',
	addUser: 'Tilføj',
	backToGame: 'Tilbage',
	createSchool: 'Opret Skole',
	createSchoolPopup: {
		title: 'Opret skole',
		title2: 'Rediger skole',
		schoolName: 'Skolenavn',
		school: 'Skole',
		email: 'E-mail',
		password: 'Password',
		games: 'Spil',
		create: 'Opret',
		edit: 'Rediger'
	},
	deleteSchoolPopup: {
		title: 'Slet skole',
		text: 'Er du sikker på, at du vil slette skolen "%school%"?',
		confirmDelete: 'Slet skole',
		cancelDelete: 'Fortryd',
	},
	schoolList: {
		school: 'SKOLE',
		email: 'E-MAIL',
		password: 'PASSWORD',
		status: 'STATUS',
		students: 'ELEVER',
		playthroughs: 'AFVIKLINGER',
		edit: 'REDIGER',
		delete: 'SLET',
	}
};

const schoolUiTexts = {
	admin: 'Admin',
	back: 'Tilbage',
	gameCode: 'Spilkode',
	startGameBtn: 'Start spil',
	closeGameBtn: 'Afslut spil',
	countdownTimer: 'Countdown timer',
	leaderboard: 'Leaderboard',
	rank: 'Rank',
	name: 'Navn',
	points: 'Point',
	video: 'Video',
	downloadManual: 'Download manual',
	noAccessPage: {
		noAccess: 'Din skole, <b>%school%</b>, har ikke adgang til spillet %scenario%.'
	},
	landingPage: {
		createGame: 'Opret nyt spil',
		studentsField: 'Elever:',
		playThroughField: 'Afviklinger:',
		game: 'SPIL',
		gameCode: 'SPILKODE',
		choose: 'VÆLG',
		goToGame: 'Gå til spil',
		start: 'Start spil',
		continue: 'Fortsæt spil',
		viewResults: 'Se resultater',
		delete: 'SLET'
	},
	deleteGamePopup: {
		title: 'Slet spil',
		text: 'Er du sikker på, du vil slette spillet "%game%"?',
		cancelBtn: 'Fortryd',
		confirmBtn: 'Slet spil',
	},
	noGroupsWarning: {
		title: 'Start spil',
		text: 'Der er ikke nogen grupper logget ind, er du sikker på, du vil starte spillet?',
		cancelBtn: 'Fortryd',
		confirmBtn: 'Start spil'
	},
	closeGameWarning: {
		title: 'Afslut spil',
		text: 'Er du sikker på ...?',
		cancelBtn: 'Fortryd',
		confirmBtn: 'Afslut spil'
	},
	gameDonePage: {
		title: 'Final score',
	},

	gameReadyPage: {
		group: 'Grupper',
	},
};

const gameUiTexts = {
	resetBtn: 'Nulstil',
	backBtn: 'Tilbage',
	welcomeTo: 'Velkommen til',
	goToOffice: 'Gå ind på dit kontor',
	timesOut: 'tiden er gået',
	hint: 'Hint',
	choose: 'Vælg',
	ok: 'OK',
	points: 'Point',
	handOver: 'Aflever',
	correctAnswer: 'Korrekt svar',
	gameNotBegun: 'Spillet er ikke begyndt endnu',
	gameOver: 'Spillet er slut',
	nextAssignment: 'Næste opgave',
	continueBtn: 'Videre',
	exitAssignments: 'Tilbage til kontoret',
	thankYouForPlaying: 'Tak fordi du spillede',
	qrCodeInfo: 'Luk ikke skærmen, om lidt skal du bruge denne QR kode:'
};

const generalUiTexts = {
}

export {
	cookieUiTexts,
	errorUiTexts,
	loginUiTexts,
	adminUiTexts,
	schoolUiTexts,
	gameUiTexts,
	generalUiTexts
};