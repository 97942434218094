import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import TaskIntro from 'components/game-engines/task-intro/task-intro';
import Button from 'components/ui/button/button';
import './spot-errors.scss';

const SpotErrors = ({isCompleted, playerTaskData, taskData, handleUpdateTask, handleCompleteTask, scenarioId}) => {
	/* Track selected options and misses (background clicks) */
	const [spottedErrorIds, setSpottedErrorIds] = useState([]);
	const [numberOfMisses, setNumberOfMisses] = useState(0);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSpottedErrorIds = () => {
		let errorIds = [];
		if (playerTaskData && playerTaskData.spottedErrorIds) {
			errorIds = playerTaskData.spottedErrorIds;
		}
		return errorIds;
	};

	/**
	 * Get number of misses (background clicks)
	 * @returns {number} misses
	 */
	const getNumberOfMisses = () => {
		let misses = 0;
		if (playerTaskData && playerTaskData.misses) {
			misses = playerTaskData.misses;
		}
		return misses;
	};

	/* New task: spotted errors, and countdown  */
	useEffect(() => {
		setSpottedErrorIds(getSpottedErrorIds());
		setNumberOfMisses(getNumberOfMisses());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Spot error
	 * @param {string} errorId 
	 * @returns 
	 */
	const spotErrorId = (errorId) => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Already spotted */
		if (spottedErrorIds.indexOf(errorId) >= 0) return;

		/* Spot error */
		let newSpottedErrorIds = JSON.parse(JSON.stringify(spottedErrorIds));
		newSpottedErrorIds.push(errorId);
		setSpottedErrorIds(newSpottedErrorIds);

		if (taskData.doneWhenAllErrorsSpotted && newSpottedErrorIds.length === taskData.errors.length) {
			/* All errors spotted: complete task */
			completeTask(newSpottedErrorIds);
		} else {
			handleUpdateTask(
				'spot-errors', 
				{spottedErrorIds: newSpottedErrorIds, errors: 0, misses: numberOfMisses}
			);
		}
	};

	/**
	 * Click on background
	 */
	const backgroundClick = () => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Update misses count */
		const newNumberOfMisses = numberOfMisses + 1;
		setNumberOfMisses(newNumberOfMisses);

		/* Update task */
		handleUpdateTask(
			'spot-errors', 
			{spottedErrorIds: spottedErrorIds, errors: 0, misses: newNumberOfMisses}
		);
	};

	/**
	 * Complete task
	 */
	const completeTask = (newSpottedErrorIds = null) => {
		/* Get spotted errors */
		const finalSpottedErrors = (newSpottedErrorIds ? newSpottedErrorIds : spottedErrorIds);

		/* Errors (background cliks + errors not spotted) */ 
		const errors = (taskData.errors.length - finalSpottedErrors.length); // + numberOfMisses;
		
		
		/* Save completed task */
		handleCompleteTask(
			'spot-errors', 
			{spottedErrorIds: spottedErrorIds, errors: errors, misses: numberOfMisses}
		);
	};

	return (
		<div className={'SpotErrors' + (taskData.layout ? ' ' + taskData.layout : '') 
			+ (isCompleted ? ' completed' : '')}>
			{/* Task intro */}
			{taskData.text && <div className="PaperDoll-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
					scenarioId={scenarioId}
				/>
			</div>}

			{/* Spot errors */}
			<div className="SpotErrors-errors" onClick={() => {backgroundClick();}}>
				{taskData.errors.map((error) => {
					return (
						<div 
							key={error.id} 
							className={'SpotErrors-error ' + error.id 
								+ (spottedErrorIds.indexOf(error.id) >= 0 ? ' spotted' : '')}
							onClick={(e) => {e.stopPropagation(); spotErrorId(error.id);}} 
						/>
					);
				})}
			</div>

			{/* Confirm btn */}
			{(!isCompleted && !taskData.doneWhenAllErrorsSpotted) && 
				<div className="SpotErrors-confirmBtn" onClick={(e) => {e.stopPropagation();}} >
					<Button 
						classes={['confirmTask', scenarioId]} 
						text={gameUiTexts.ok} 
						onClick={() => {completeTask();}}
					/>
				</div>
			}
		</div>
	);
};

SpotErrors.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleUpdateTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default SpotErrors;
