import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {gameUiTexts} from 'data/ui-texts';
import TaskIntro from 'components/game-engines/task-intro/task-intro';
import Button from 'components/ui/button/button';
import './paper-doll.scss';

const PaperDoll = ({isCompleted, playerTaskData, taskData, handleCompleteTask, scenarioId}) => {
	/* Track available and selected items */
	const [itemIds, setItemIds] = useState([]);
	const [selectedItemIds, setSelectedItemIds] = useState([]);


	/**
	 * Get item ids, shuffle them
	 * @returns {array} optionIds
	 */
	 const getItemIds = () => {
		if (!taskData.items) return [];
		return shuffleArray(taskData.items.map((i) => {return i.id;}));
	};

	/**
	 * Get selected item ids
	 * @returns {array} itemIds
	 */
	const getSelectedItemIds = () => {
		let itemIds = [];
		if (playerTaskData && playerTaskData.selectedItemIds) itemIds = playerTaskData.selectedItemIds;
		return itemIds;
	};

	/* Update selected items if new task */
	useEffect(() => {
		setItemIds(getItemIds());
		setSelectedItemIds(getSelectedItemIds());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Toggle item
	 * @param {string} itemId 
	 * @returns 
	 */
	const toggleItem = (itemId) => {
		/* Already completed */
		if (isCompleted) return;

		/* Toggle item id */
		let newSelectedItemIds = [...selectedItemIds];
		const itemIndex = newSelectedItemIds.indexOf(itemId);
		if (itemIndex < 0) {
			/* Select item */
			newSelectedItemIds.push(itemId);
		} else {
			/* De-select item */
			newSelectedItemIds.splice(itemIndex, 1);			
		}
		setSelectedItemIds(newSelectedItemIds);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {		
		/* Errors */
		// let numberOfCorrectItems = 0;
		let missedItems = [];
		let excessItems = [];
		taskData.items.forEach((item) => {
			if (item.isCorrect) {
				// numberOfCorrectItems += 1;
				if (selectedItemIds.indexOf(item.id) < 0) missedItems.push(item.title);	
			} else {
				if (selectedItemIds.indexOf(item.id) >= 0) excessItems.push(item.title);
			}
		});
		const errors = missedItems.length + excessItems.length;

		/* Save completed task */
		handleCompleteTask(
			'paper-doll', 
			{selectedItemIds: selectedItemIds, errors: errors}
		);
	};

	return (
		<div className={'PaperDoll' + (taskData.layout ? ' ' + taskData.layout : '')}>
			{/* Task intro */}
			{taskData.text && <div className="PaperDoll-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
					scenarioId={scenarioId}
				/>
			</div>}

			<div className="PaperDoll-content">
				{/* Available items */}
				<div className="PaperDoll-availableItems">
					{itemIds.map((itemId) => {
						let itemClass = 'PaperDoll-item ' + itemId;
						const itemData = taskData.items.find((i) => {return i.id === itemId;});
						const isSelected = selectedItemIds.indexOf(itemId) >= 0;
						
						if (isSelected) {
							itemClass += ' selected';
							if (isCompleted) {
								itemClass += ' ' + (itemData.isCorrect ? 'correct' : 'wrong');
							}
						} else {
							if (isCompleted && itemData.isCorrect) {
								itemClass += ' wrong';
							}
						}
						return (
							<div 
								key={itemId} 
								className={itemClass}
								onClick={() => {toggleItem(itemId);}}
							/>
						);
					})}
				</div>

				{/* Avatar with selected items */}
				<div className={'PaperDoll-selectedItems ' + taskData.avatarId}>
					<div className="PaperDoll-avatar" />
					{taskData.items.map((item) => {
						if (selectedItemIds.indexOf(item.id) < 0) return null;

						let itemClass = 'PaperDoll-item ' + item.id;
						if (isCompleted) {
							itemClass += ' completed';
							const itemData = taskData.items.find((i) => {return i.id === item.id;});
							if (!itemData.isCorrect) {
								itemClass += ' wrong';
							} else {
								itemClass += ' correct';
							}
						}
						return (
							<div 
								key={item.id} 
								className={itemClass}
								onClick={() => {toggleItem(item.id);}}
							/>
						);
					})}
				</div>
			</div>

			
			{/* Done button */}
			{!isCompleted && <div className="PaperDoll-confirmBtn">
				<Button 
					classes={['confirmTask', scenarioId]} 
					text={gameUiTexts.ok} 
					onClick={() => {completeTask();}}
				/>
			</div>}
		</div>
	);
};

PaperDoll.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default PaperDoll;
