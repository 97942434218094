let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let schoolsDbName = 'schools';
let gamesDbName = 'games';
let groupsDbName = 'groups';
let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-karriereindsatsen-n-test.web.app';
	apiURL = 'https://cgl-karriereindsatsen-n-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-karriereindsatsen-n-demo.web.app';
		apiURL = 'https://cgl-karriereindsatsen-n-demo.web.app/api/';
		schoolsDbName = 'schools-demo';
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
	}
}
if (env === 'production') {
	gameUrl = 'cgl-karriereindsatsen-n-prod.web.app';
	apiURL = 'https://cgl-karriereindsatsen-n-prod.web.app/api/';
}

const appConfig = {
	env: env,
	apiURL: apiURL,
	gameUrl: gameUrl,
	schoolsDbName,
	gamesDbName: gamesDbName,
	groupsDbName: groupsDbName,
	groupNameMinLength: 3,
	groupNameMaxLength: 15,
	gameDurationMinutes: 40,
	minActiveGroupsForHint: 4,
	cookiesAcceptedCookieName: 'karriereindsatsen-n_cookies_accepted',
	youtubeVideoId: ''
};

export default appConfig;