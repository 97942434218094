import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts, schoolUiTexts} from 'data/ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import Logo from '../ui/logo/logo';
import Logos from 'components/ui/logos/logos';
import './no-access-page.scss';

const NoAccessPage = (
	{
		scenarioId, 
		schoolData, 
		handleLogout, 
		updateBackground 
	}
) => {

	/* No access text */
	let text = JSON.parse(JSON.stringify(schoolUiTexts.noAccessPage.noAccess));
	if (schoolData) {
		text = text.replace('%school%', schoolData.name);
	}
	const scenarioData = scenariosData[scenarioId];
	if (scenarioData) {
		text = text.replace('%scenario%', scenarioData.title);
	}
	
	

	useEffect(() => {
		// Set background image id
		updateBackground('login', scenarioId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<div className={'NoAccessPage'}>
			<Logos />
			<div className='NoAccessPage-logoWrapper'>
				<Logo scenarioId={scenarioId} page="landing" />
			</div>
			<div className="NoAccessPage-content">
				<span>{renderMarkdown(text)}</span>
			</div>



			{/* Logout button */}
			<div className="NoAccessPage-logoutBtn">
				<Button 
					classes={['login', 'logout']} 
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Back to select game btn */}
			<div className="NoAccessPage-backBtn">
				<Button 
					classes={['backBtnGame', scenarioId]} 
					text={schoolUiTexts.back}
					type="a"
					href="/"
				/>
			</div>
		</div>
	);
};

NoAccessPage.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	schoolData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired
};

export default NoAccessPage;
