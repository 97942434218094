import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {renderMarkdown} from 'helpers/text-helper';
import TaskIntro from 'components/game-engines/task-intro/task-intro';
import './pairs.scss';

const Pairs = ({isCompleted, playerTaskData, taskData, handleUpdateTask, handleCompleteTask, scenarioId}) => {
	/* Timeout */
	let timeout = useRef(null);

	/* Track items in column A and B */
	const [columnAItemIds, setColumnAItemIds] = useState([]);
	const [columnBItemIds, setColumnBItemIds] = useState([]);
	const [selectedItemIds, setSelectedItemIds] = useState([]);
	const [pairedItemsColumnAIds, setPairedItemsColumnAIds] = useState([]);
	const [errors, setErrors] = useState(0);

	/**
	 * Get items, shuffle them
	 * @returns {array} optionIds
	 */
	const getColumnAItems = () => {
		if (!taskData.columnAItems) return [];
		return shuffleArray(taskData.columnAItems.map((i) => {return i.id;}));
	};
	const getColumnBItems = () => {
		if (!taskData.columnBItems) return [];
		return shuffleArray(taskData.columnBItems.map((i) => {return i.id;}));
	};

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getPairedItemsColumnAIds = () => {
		let pairedItemsColumnAIds = [];
		if (playerTaskData && playerTaskData.pairedItemsColumnAIds) {
			pairedItemsColumnAIds = playerTaskData.pairedItemsColumnAIds;
		}
		return pairedItemsColumnAIds;
	};


	
	/* Update selected items if new task */
	useEffect(() => {
		setColumnAItemIds(getColumnAItems());
		setColumnBItemIds(getColumnBItems());
		setSelectedItemIds([]);
		setPairedItemsColumnAIds(getPairedItemsColumnAIds());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Component will mount/unmount */
	useEffect(() => {
		return () => {
			/* Component will unmount */
			if (timeout.current) clearTimeout(timeout.current);
		};
	}, []);


	/**
	 * Get item data
	 * @param {string} itemId 
	 */
	const getItemData = (itemId) => {
		let itemData = null;
		if (columnAItemIds.indexOf(itemId) >= 0) {
			itemData = JSON.parse(JSON.stringify(taskData.columnAItems.find((i) => {
				return i.id === itemId;
			})));
			itemData.column = 'A';
		}

		if (columnBItemIds.indexOf(itemId) >= 0) {
			itemData = JSON.parse(JSON.stringify(taskData.columnBItems.find((i) => {return i.id === itemId;})));
			itemData.column = 'B';
		}		

		return itemData;
	};

	/**
	 * Toggle item
	 * @param {number} itemId 
	 * @returns 
	 */
	const toggleItemId = (itemId) => {
		/* Already completed */
		if (isCompleted === true) return;

		/* Is already compairing 2 items */
		if (selectedItemIds > 1) return;

		/* Get data of item */
		const itemData = getItemData(itemId);
		if (!itemData) return;

		/* Prepare updates */
		let newSelectedItemIds = JSON.parse(JSON.stringify(selectedItemIds));
		let newPairedItemsColumnAIds = JSON.parse(JSON.stringify(pairedItemsColumnAIds));
		let newErrors = errors;

		if (selectedItemIds.length === 0) {
			/* 1st item selected */
			newSelectedItemIds.push(itemId);
		} else if (selectedItemIds.indexOf(itemId) >= 0) {
			/* Item is already selected -> de-select item */
			newSelectedItemIds = [];
		} else  {
			/* 2nd item selected */
			const firstItemData = getItemData(selectedItemIds[0]);
			if (itemData.column === firstItemData.column) {
				/* Same column, switch selection */
				newSelectedItemIds = [itemId];
			} else {
				/* Select item */
				newSelectedItemIds.push(itemId);

				/* Compare selected */
				const itemAData = (itemData.column === 'A' ? itemData : firstItemData);
				const itemBData = (itemData.column === 'A' ? firstItemData : itemData);
				const isCorrect = (itemAData.id === itemBData.connectedToItemId);
				if (isCorrect) {
					newPairedItemsColumnAIds = JSON.parse(JSON.stringify(pairedItemsColumnAIds));
					newPairedItemsColumnAIds.push(itemAData.id);
				} else {
					newErrors = errors + 1;
				}
			}
		}

		setSelectedItemIds(newSelectedItemIds);
		setPairedItemsColumnAIds(newPairedItemsColumnAIds);
		setErrors(newErrors);
		if (newPairedItemsColumnAIds.length === taskData.columnAItems.length) {
			/* Task completed */
			handleCompleteTask(
				'pairs',
				{errors: newErrors, pairedItemsColumnAIds: newPairedItemsColumnAIds}
			);
		} else {
			/* Task completed */
			handleUpdateTask(
				'pairs',
				{errors: newErrors, pairedItemsColumnAIds: newPairedItemsColumnAIds}
			);
		}
		if (newSelectedItemIds.length > 1) {
			/* Reset selected item ids */
			timeout.current = setTimeout(() => {
				setSelectedItemIds([]);
			}, 500);
		}
	};


	return (
		<div className={'Pairs' + (taskData.layout ? ' ' + taskData.layout : '')}>
			{/* Task intro */}
			<div className="Pairs-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
					scenarioId={scenarioId}
				/>
			</div>

			<div className="Pairs-content">
				{/* Column A */}
				<div className={'Pairs-columnA'}>
					{columnAItemIds.map((itemId) => {
						const itemData = taskData.columnAItems.find((i) => {return i.id === itemId;});
						if (!itemData) return null;
						const selectedIndex = selectedItemIds.indexOf(itemId);
						const isSelected = (selectedIndex >= 0);
						let itemClass = 'Pairs-item ' + scenarioId;
						if (isSelected) {
							itemClass += ' selected';
							if (selectedItemIds.length > 1) {
								const itemBData = taskData.columnBItems.find((i) => {
									return (i.id === selectedItemIds[1 - selectedIndex]);
								});
								if (itemBData) {
									const isCorrect = (itemData.id === itemBData.connectedToItemId);
									itemClass += ' ' + (isCorrect ? 'animateCorrect' : 'animateWrong');
								}
							}
						} else if (
							isCompleted ||
							pairedItemsColumnAIds.indexOf(itemId) >= 0
						) {
							itemClass += ' completed';
						}
						return (
							<div 
								key={itemId} 
								className={itemClass} 
								onClick={() => {toggleItemId(itemId);}}
							>
								{itemData.text && <span>{renderMarkdown(itemData.text)}</span>}
							</div>
						);
					})}
				</div>

				{/* Column B */}
				<div className={'Pairs-columnB'}>
					{columnBItemIds.map((itemId) => {
						const itemData = taskData.columnBItems.find((i) => {return i.id === itemId;});
						if (!itemData) return null;
						const selectedIndex = selectedItemIds.indexOf(itemId);
						const isSelected = (selectedIndex >= 0);
						let itemClass = 'Pairs-item ' + scenarioId;
						if (isSelected) {
							itemClass += ' selected';
							if (selectedItemIds.length > 1) {
								const itemAData = taskData.columnAItems.find((i) => {
									return (i.id === selectedItemIds[1 - selectedIndex]);
								});
								if (itemAData) {
									const isCorrect = (itemAData.id === itemData.connectedToItemId);
									itemClass += ' ' + (isCorrect ? 'animateCorrect' : 'animateWrong');
								}
							}
						} else if (
							isCompleted ||
							pairedItemsColumnAIds.indexOf(itemData.connectedToItemId) >= 0
							
						) {
							itemClass += ' completed';
						}
						return (
							<div 
								key={itemId} 
								className={itemClass} 
								onClick={() => {toggleItemId(itemId);}}
							>
								{itemData.text && <span>{renderMarkdown(itemData.text)}</span>}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Pairs.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleUpdateTask: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default Pairs;
