const taskTemplates = require('../../task-templates');

const tasksData = [		
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'build-multiple-choice-volume-cylinder',
		text: 'Den nye tank skal have cylinderform. Hvad er formlen for at finde rumfanget af en cylinder?',
		popupText: `Rumfanget eller voluminet af en cylinder afhænger af arealet af cirkelbunden ganget med højden.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '&pi; * r<sup>2</sup> * h', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '2 * &pi; * r'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'h * l * b', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '2 * &pi; * (h+r)', 
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'build-multiple-choice-tank-radius',
		text: 'Tanken skal kunne indeholde 50.000 L og være 1,77 m høj, så man kan omrøre i tanken. Hvad skal radius, r, af tanken være?',
		hintText: `I har fået formlen i tidligere opgave - måske I kan regne baglæns?`,
		popupText: 'h = (50000 * 10<sup>-3</sup> m<sup>3</sup>) / &pi; * (3 m)<sup>2</sup>',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1,0 m', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '2,0 m'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '3,0 m', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '4,0 m', 
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'build-multiple-choice-room-enough',
		text: 'Nu ved vi, hvor stor tanken er. Vurdér ud fra tegningen i bilaget, om der er plads til endnu en tank i produktionsrummet.',
		popupText: 'Det udvendige areal af pruduktionsrummet er 126 m^2, men de indvendige mål er ca. 11 m x 7 m = 77 m^2. Tankens diameter er 6 m, og der kan derfor ikke være to i rummet.',
		hintText: `Hvis I er i tvivl, kan I evt måle med på skærmen. `,
		image: 'build-floor-plan',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Det er der', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det er der ikke',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 4,
		taskId: 'build-order-material',
		title: 'Opgave 4',
		text: `Løsningen bliver at vælte væggen indtil lokalet ved siden af for at gøre plads til den nye tank. Men hvilket materiale, vil være bedst at bygge tanken i. Sæt mulighederne i rækkefølge fra bedst til værst. Tryk ok, når I er klar.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'steel', 
				text: `Rustfrit stål`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'copper', 
				text: `Kobber`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'plastic', 
				text: `Plastik`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'wood', 
				text: `Træ`,
				correctOrderNumbers: [4]
			})
		]
	}),
	// CASE 2
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		taskId: 'build-sort-dangerous-chemicals',
		title: 'Opgave 1',
		text: `Under et nedrivningsprojekt fandt håndværkerene farlige restkemikalier. Sortér kemikalierne efter typer.`,
		hintText: `Brug internettet til at finde svaret`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'directlyHarmful', 
				title: `Direkte skadelige stoffer`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'waterSensitive', 
				title: `Vandfølsomme stoffer`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'oxydating',
				title: `Oxideringsmidler`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'poisonous',
				title: `Giftige stoffer`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Pestmidler`,
				categoryIds: ['directlyHarmful']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Opløsningsmidler`,
				categoryIds: ['directlyHarmful']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Natrium`,
				categoryIds: ['waterSensitive']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Lithium`,
				categoryIds: ['waterSensitive']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Klor`,
				categoryIds: ['oxydating']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: `Asbest`,
				categoryIds: ['poisonous']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, 
				text: `Kviksølv`,
				categoryIds: ['poisonous']
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		taskId: 'build-sort-acid-base',
		title: 'Opgave 2',
		text: `Det er vigtigt at kunne skelne mellem syrer og baser. Træk stofferne hen på den rigtige type. `,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'acid', 
				title: `Syre`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'base', 
				title: `Base`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Svovltrioxid`,
				categoryIds: ['acid']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Phosphorpentoxid`,
				categoryIds: ['acid']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Natriumhydroxid`,
				categoryIds: ['base']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Calciumoxid`,
				categoryIds: ['base']
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 3,
		subtype: 'images',
		title: 'Opgave 3',
		taskId: 'build-multiple-choice-warning-labels',
		text: `I er blevet opmærksomme på, at der opbevares brandfarlige stoffer i bygningen. Der skal derfor opsættes et advarselstegn. Vælg det rigtige.`,
		layout: 'build-warning',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'flammable', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'explosive',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'irritant' 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'acute-toxicity',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'gases',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'corrosive',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'build-multiple-choice-reduce-energy',
		text: `Hvordan kan man reducere sit energiforbrug i produktionshallen?`,
		layout: 'build-warning',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Investering i energieffektive maskiner',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Højintensitetslamper',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Automatisering og styringssystemer',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Brug af traditionelle energikilder',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Energiforbrugsmonitorering',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Brug af alternative energikilder',
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		taskId: 'build-sort-for-against',
		title: 'Opgave 2',
		text: `Hvad kunne argumentere for og imod øget genbrug hos Danish Nutrition?`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'for', 
				title: `For øget genbrug`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'against', 
				title: `Imod øget genbrug`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Reducerer affaldsmængde`,
				categoryIds: ['for']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Forbedrer virksomhedens omdømme`,
				categoryIds: ['for']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Reducerer ressourceforbrug`,
				categoryIds: ['for']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Øgede omkostninger`,
				categoryIds: ['against']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Potentielle kvalitetsproblemer`,
				categoryIds: ['against']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: `Kompleksiteten af genbrugsprocessen`,
				categoryIds: ['against']
			})
		]
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'htx-build-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};