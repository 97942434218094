import appConfig from 'config/app.config';
import {
	defaultMaxPoints, 
	defaultMinPoints, 
	errorPenalty, 
	inputAnswerMaxAttempts, 
	inputAnswerMinPoints
} from 'data/points-data';
import {scenariosData} from 'data/scenarios-data';
import {sortArrayByProperty} from './array-helper';

/**
 * Get task data
 * @param {string} scenarioId 
 * @param {string} areaId 
 * @param {number} caseId 
 * @param {number} taskNumber 
 * @returns 
 */
export function getTaskData(scenarioId, areaId, caseId, taskNumber) {
	let taskData = null;
	if (
		scenariosData.hasOwnProperty(scenarioId) && 
		scenariosData[scenarioId].hasOwnProperty('tasksData') &&
		scenariosData[scenarioId].tasksData.hasOwnProperty(areaId) &&
		scenariosData[scenarioId].tasksData[areaId].length > 0 &&
		scenariosData[scenarioId].tasksData[areaId].some((c) => {
			return (c && c.caseId === caseId && c.taskNumber === taskNumber);
		})
	) {
		taskData = scenariosData[scenarioId].tasksData[areaId].find((c) => {
			return (c && c.caseId === caseId && c.taskNumber === taskNumber);
		});
	}

	return taskData;
};

/**
 * Get group points
 * @param {object} group 
 * @returns 
 */
export function getGroupPoints(group) {
	let points = 0;
	if (group.assignments && group.assignments.length > 0) {
		
		group.assignments.forEach((assignment) => {
			if (assignment.isCompleted && assignment.points) {
				points += assignment.points;
			}
		});
	}

	return points;
};

/**
 * Check if hint btn should be shown
 * @param {string} groupId 
 * @param {array} groups 
 * @returns 
 */
export function checkIfShowHintBtn(groupId, groups) {
	/* Default value */
	let showHintBtn = false;

	if (groups.length >= appConfig.minActiveGroupsForHint) {
		/* Get and order group points */
		let groupPoints = groups.map((group) => {
			const points = getGroupPoints(group);
			return {id: group.id, points: points};
		});
		groupPoints = sortArrayByProperty(groupPoints, 'points', 'DESC');
		/* Get group rank */
		const groupIndex = groupPoints.findIndex((group) => {return group.id === groupId;});
		if (groupPoints[groupIndex].points > 0 && groupIndex > (groupPoints.length / 2)) {
			/* Group is in bottom half -> show hint btn */
			showHintBtn = true;
		}
	}

	return showHintBtn;
};

/**
 * Checks through area task data, and compares to group completed tasks.
 * @param {string} scenarioId 
 * @param {string} areaId 
 * @param {object} group 
 * @returns true/false depending on whether group has completed all tasks in area or not
 */
export function checkIfGroupHasCompletedArea(scenarioId, areaId, groupAssignmentdata) {
	if (
		scenariosData.hasOwnProperty(scenarioId) && 
		scenariosData[scenarioId].hasOwnProperty('tasksData') &&
		scenariosData[scenarioId].tasksData.hasOwnProperty(areaId) &&
		scenariosData[scenarioId].tasksData[areaId]
	) {
		const areaData = scenariosData[scenarioId].tasksData[areaId];
		const completedAssignments = groupAssignmentdata.filter((assignment) => {
			return assignment.isCompleted && assignment.taskId.includes(areaId);
		});

		if (completedAssignments.length < areaData.length) {
			return false;
		}

		let completedAllTasks = true;
		areaData.forEach((data) => {
			const taskIsCompleted = completedAssignments.findIndex((assignment) => {
				return assignment.taskId === data.taskId;
			}) !== -1;

			if (!taskIsCompleted) {
				completedAllTasks = false;
				return;
			}
		});

		return completedAllTasks;
	}
	
	return false;
};


/**
 * Check if group has completed specific task
 * @param {string} taskId 
 * @param {object} group 
 * @returns 
 */
export function checkIfGroupHasCompletedTask(taskId, group) {
	let groupHasCompletedTask = false;
	if (
		group.assignments &&
		group.assignments.some((a) => {return a.taskId === taskId;}) 
	) {
		const groupAssignmentData = group.assignments.find((a) => {return a.taskId === taskId;});
		if (
			groupAssignmentData.isCompleted === true	
		) {
			groupHasCompletedTask = true;
		}
	}

	return groupHasCompletedTask;
};

/**
 * Check if a task i locked
 * @param {object} task 
 * @param {object} group 
 * @returns 
 */
export function checkIfTaskIsLocked(task, group) {
	const lockedById = task.lockedById;
	if (!lockedById) {
		return false;
	}

	const groupHasCompletedRequiredTask = checkIfGroupHasCompletedTask(lockedById, group);
	const taskIsLocked = !groupHasCompletedRequiredTask;
	return taskIsLocked;
}

/**
 * Get next tasknumber if there is one
 * @param {string} currentTaskNumber 
 * @param {array} tasks 
 * @returns returns a string, or null if no next task
 */
export function getNextTaskNumberIfAble(currentTaskNumber, tasks) {
	// Find index of currentTaskNumber
	const currentIndex = tasks.findIndex((task) => {
		return task.taskNumber === currentTaskNumber;
	});
	// If not found, return null
	if (currentIndex === -1) {
		return null;
	}
	// Check if next index exists
	if (tasks.length - 1 >= currentIndex + 1) {
		// If yes, return task number
		return tasks[currentIndex + 1].taskNumber;
	}

	// If no return null
	return null;
}

/**
 * Calculate number of errors
 * @param {string} type 
 * @param {array} attempts 
 * @returns 
 */
export function calculateErrors(type, attempts) {
	let errors = 0;

	if (type === 'input-answer') {
		errors = attempts.filter((a) => {return a.errors > 0;}).length;
	}

	if (
		type === 'multiple-choice' || 
		type === 'sort' || 
		type === 'paper-doll' ||
		type === 'spot-errors' ||
		type === 'pairs'
	) {
		errors = attempts[attempts.length - 1].errors;
	}

	if (type === 'order') {
		errors = attempts.length - 1;
	}

	return errors;
}

/**
 * Calculate points
 * @param {string} type 
 * @param {number} errors 
 * @param {number} maxPoints 
 * @param {object} taskAnswerData
 * @returns 
 */
export function calculatePoints(type, errors, maxPoints, taskAnswerData) {
	let points = (maxPoints ? maxPoints : defaultMaxPoints);

	/* Multiple choice / sort / order */
	if (
		type === 'multiple-choice' || 
		type === 'sort' || 
		type === 'order' || 
		type === 'paper-doll' ||
		type === 'spot-errors' ||
		type === 'pairs'
	) {
		points = points - errors * errorPenalty;
		points = Math.max(defaultMinPoints, points);
	}

	/* Multiple choice dilemma */
	if (type === 'multiple-choice-dilemma') {
		points = 0;
		if (taskAnswerData && taskAnswerData.specialPoints) points = taskAnswerData.specialPoints;
	}

	/* Input answer */
	if (type === 'input-answer') {
		if (errors < inputAnswerMaxAttempts) {
			points = points - errors * errorPenalty;
		} else {
			points = inputAnswerMinPoints;
		}
	}

	return points;
}

/**
 * Returns completion data relative to given list of task data
 * Contains completed amount, completion percentage
 * @param {Array} taskData 
 * @param {Array} groupTaskData 
 */
export function calculateCompletionData(taskData, groupTaskData) {
	const taskCount = taskData.length;

	if (taskCount === 0) {
		return {
			completedPercentage: 100,
			completionCount: 0
		};
	}

	let completionCount = 0;

	if (groupTaskData) {		
		taskData.forEach((task) => {
			const groupTask = groupTaskData.find((groupTask) => {
				return groupTask.taskId === task.taskId && groupTask.isCompleted;
			});

			if (groupTask) {
				completionCount++;
			}
		});
	}

	return {
		completedPercentage: (completionCount / taskCount) * 100, 
		completionCount: completionCount
	};
}