import React from 'react';
import PropTypes from 'prop-types';
import { loginUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './login-group.scss';

const LoginGroup = (props) => {
	const {
		isLoading,
		showCreateNewGroup,
		code,
		groups,
		groupId,
		groupName,
		feedback,
		handleInput,
		handleFindGame,
		toggleShowCreateNewGroup,
		handleCreateGroup,
		handleGetLoginToken,
		scenarioId
	} = props;

	/**
	 * Checks whether name is valid
	 * @returns boolean
	 */
	const nameIsValid = () => {
		// No name
		if (!groupName) {
			return false;
		}

		// Name contains only white space
		const trimmedName = groupName.trim();
		return trimmedName.length > 0;
	};

	/* Input game code */
	if (groups === null) {
		return (
			<div className={'LoginGroup ' + scenarioId}>
				<div className="LoginGroup-title"><span>{loginUiTexts.groupLogin}</span></div>
				<div className="LoginGroup-body">
					<div className='LoginGroup-text'><span>{loginUiTexts.useGameCode}</span></div>
					<form className="LoginGroup-form" onSubmit={(e) => {handleFindGame(e);}}>
						<input
							name="code"
							type="text"
							placeholder={loginUiTexts.gameCode} 
							autoComplete="section-grouplogin code"
							value={code ? code : ''}
							onChange={(event)=>{handleInput(event);}}
						/>
						<div className="LoginGroup-loginBtn">
							<Button 
								isLoading={isLoading}
								classes={['login']}
								text={loginUiTexts.login}
								onClick={() => {handleFindGame();}}
							/>
						</div>
						<div className="LoginGroup-errorMessage">{feedback}</div>
						<div className="LoginGroup-intructions"><span>{loginUiTexts.groupLoginIntruction}</span></div>
					</form>
				</div>
			</div>
		);
	}

	/* Select group */
	if (!showCreateNewGroup) {
		return (
			<div className="LoginGroup groups">
				<div className="LoginGroup-title"><span>{loginUiTexts.groupLogin}</span></div>
				<div className="LoginGroup-body">
					<div className="LoginGrouop-createGroupBtn">
						<Button 
							classes={['login']}
							text={loginUiTexts.newGroup} 
							onClick={() => {toggleShowCreateNewGroup();}}
						/>
					</div>
					<div className="LoginGroup-groups">
						{groups.map((group, index)=>{
							return (
								<Button 
									key={index}
									isLoading={isLoading && group.id === groupId}
									classes={['select-group']}
									text={group.name ? group.name : group.id}
									onClick={() => {handleGetLoginToken(group.id);}}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	/* Create new group */
	return (
		<div className="LoginGroup create">
			<div className="LoginGroup-title"><span>{loginUiTexts.groupLogin}</span></div>
			<div className="LoginGroup-body">
				<div className='LoginGroup-text'><span>{loginUiTexts.writeGroupName}:</span></div>
				<form className="LoginGroup-form" onSubmit={(e) => {handleCreateGroup(groupName, e);}}>
					<input
						name="groupName"
						type="text"
						placeholder={loginUiTexts.groupName} 
						autoComplete="section-grouplogin name"
						value={groupName ? groupName : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="LoginGroup-createBtn">
						<Button 
							isDisabled={!nameIsValid()}
							isLoading={isLoading}
							classes={['login']} 
							text={loginUiTexts.create} 
							onClick={() => {handleCreateGroup(groupName);}}
						/>
					</div>
					<div className="LoginGroup-cancelBtn">
						<Button 
							classes={['login']} 
							text={loginUiTexts.cancel} 
							onClick={() => {toggleShowCreateNewGroup();}}
						/>
					</div>
					<div className="LoginGroup-errorMessage">{feedback}</div>
				</form>
			</div>
		</div>
	);
		
};

LoginGroup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	showCreateNewGroup: PropTypes.bool.isRequired,
	code: PropTypes.any,
	groups: PropTypes.array,
	groupId: PropTypes.string,
	groupName: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	toggleShowCreateNewGroup: PropTypes.func.isRequired,
	handleCreateGroup: PropTypes.func.isRequired,
	handleGetLoginToken: PropTypes.func.isRequired,
	scenarioId: PropTypes.string.isRequired,
};

export default LoginGroup;